import React, { useRef, useState, useContext } from "react";
import { projectFirestore, auth, projectStorage as storage } from "../firebase";
import firebase from "firebase/compat/app";
import PostResponseDisplay from "./PostResponseDisplay";
import { v4 } from "uuid";
import GroupsContext from "./Contexts/GroupsContext";
import ReactQuill from "react-quill";

function PostResponse({ subjectId }) {
  
  const values = useContext(GroupsContext);
  const [lesson, setLesson] = useState([]);
  const [file_name1, setFileName1] = useState("");

  const [lessonAttributes, setLessonAttributes] = useState({
    description: "",
    file1: null,
    file_name1,
  });

  const handleLessonAttributeChange = (value, attributeName) => {
    setLessonAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: value,
    }));
  };

  const handleAddLesson = (e) => {
    e.preventDefault();
    console.log("lesson",lesson)
    setLesson([...lesson, ""]);
    console.log("lesson",lesson)
  };

  const handleFileChange1 = (e) => {
    const file1 = e.target.files[0];
    const fileName = file1 ? file1.name : ""; // Extract the file name
    setFileName1(fileName);
    setLessonAttributes({ ...lessonAttributes, file1, file_name1: fileName });
  };

  const handleLessonSave = (e) => {
    e.preventDefault();
    handleAddLesson(e);
    if (lesson.length === 0) {
      return;
    }
    // Pass lesson attributes like topic, description, summary, and file to your addLessonToSubject function.
    
    values.addLessonToSubject(lesson, subjectId, lessonAttributes);
    // Clear the fields after saving
    setLesson([]);
    setLessonAttributes({
      
      description: "",
      
      file1: null,
    });
    // Clear the file name states
    setFileName1("");
  };
  //lesson, subjectId, lessonAttributes

  const dummy = useRef();
  const nameRef = useRef();
  
  
  const [isCreating, setIsCreating] = useState(false);

  

  return (
    <div>
      <div className="container xs-container-fluid mt-5 mb-5 post-response">
        <main className="subject-wrapper mt-5 post">
          <PostResponseDisplay post={subjectId} />
          <span ref={dummy}></span>
        </main>
        <form
          className="mt-2 mb-5"
        >
          <h6>Write Description</h6>
          <ReactQuill
            name="description"
            value={lessonAttributes.description}
            onChange={(e) => handleLessonAttributeChange(e, "description")}
            placeholder="Description"
            className="m-2 my-5"
          />

          <input
            type="file"
            name="file1"
            onChange={handleFileChange1}
            className="m-2"
          />
          <button
            className="btn btn-outline-dark text-primary m-2"
            onClick={(e)=>handleLessonSave(e)}
          >
            Click twice to save class
          </button>
         
        </form>
      </div>
    </div>
  );
}

export default PostResponse;
