import React, { useContext, useEffect, useState } from "react";
import { UsersContext } from "./Contexts/UsersContext";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import {Link} from "react-router-dom"
const Leaderboards = () => {
  const { users } = useContext(UsersContext);
  const { subjects: subjectsFromCommunity } = useContext(CommunityContext);
  const { subjects: subjectsFromSubjects } = useContext(SubjectsContext);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [schoolRanks, setSchoolRanks] = useState([]);
  const [showSchoolRanks, setShowSchoolRanks] = useState(false);

  // League states
  const [topLeague, setTopLeague] = useState([]);
  const [middleLeague, setMiddleLeague] = useState([]);
  const [lowerLeague, setLowerLeague] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  useEffect(() => {
    const allSubjects = [
      ...(Array.isArray(subjectsFromCommunity) ? subjectsFromCommunity : []),
      ...(Array.isArray(subjectsFromSubjects) ? subjectsFromSubjects : []),
    ];

    const fetchData = async () => {
      const usersData = [];
      const schoolsData = {};

      users.forEach((userData) => {
        const { displayName, photoURL,uid, school, is_instructor } = userData;

        let totalCorrectAnswers = 0;
        let quizzesCount = 0;
        let challengesCount = 0;
        let responsesCount = 0;
        let reviewsCount = 0;
        let userPoints = 0;

        userData.answeredQuestionsLog?.forEach((log) => {
          log.quizResults.forEach((result) => {
            if (result.isCorrect) {
              totalCorrectAnswers++;
            }
          });
        });

        allSubjects.forEach((subject) => {
          quizzesCount += subject.quizzes.filter(
            (quiz) => quiz.displayName === displayName
          ).length;
          challengesCount += subject.challenges.filter(
            (challenge) => challenge.name === displayName
          ).length;

          if (subject.challenges) {
            subject.challenges.forEach((challenge) => {
              if (challenge.responses) {
                responsesCount += challenge.responses.filter(
                  (response) => response.displayName === displayName
                ).length;

                challenge.responses.forEach((response) => {
                  if (response.reviews) {
                    reviewsCount += response.reviews.filter(
                      (review) => review.displayName === displayName
                    ).length;
                  }
                });
              }
            });
          }

          // Add points for teachers if their name matches the subject displayName
          if (subject?.uid === uid) {
            userPoints += 5;
          }
        });

        userPoints += totalCorrectAnswers; // 1 point for each correct answer
        userPoints += 2 * quizzesCount; // 5 points for each quiz
        userPoints += 2 * challengesCount; // 5 points for each challenge
        userPoints += 2 * responsesCount; // 5 points for each response
        userPoints += 2 * reviewsCount; // 3 points for each review

        const userEntry = {
          displayName,
          photoURL,
          totalCorrectAnswers,
          quizzesCount,
          challengesCount,
          responsesCount,
          reviewsCount,
          points: userPoints,
          school,
          is_instructor,
        };

        usersData.push(userEntry);

        if (school) {
          if (!schoolsData[school]) {
            schoolsData[school] = { totalPoints: 0, topLearner: null };
          }
          schoolsData[school].totalPoints += userPoints;

          // Check if this user has the highest points in the school
          if (
            !schoolsData[school].topLearner ||
            schoolsData[school].topLearner.points < userPoints
          ) {
            schoolsData[school].topLearner = {
              displayName,
              points: userPoints,
            };
          }
        }
      });

      // Sort users by points in descending order
      usersData.sort((a, b) => b.points - a.points);

      // Assign ranks and stars
      usersData.forEach((user, index) => {
        user.rank = index + 1;
        user.stars = Math.floor(user.points / 1000);
      });

      // Calculate school rankings
      const rankedSchools = Object.entries(schoolsData)
        .map(([school, data]) => ({
          school,
          totalPoints: data.totalPoints,
          topLearner: data.topLearner,
        }))
        .sort((a, b) => b.totalPoints - a.totalPoints)
        .map((school, index) => ({
          ...school,
          rank: index + 1,
        }));

      // Divide users into leagues
      const top20 = usersData.slice(0, 5);
      const next30 = usersData.slice(5, 10);
      const remaining = usersData.slice(10,100);

      setTopLeague(top20);
      setMiddleLeague(next30);
      setLowerLeague(remaining);
      setLeaderboardData(usersData);

      setSchoolRanks(rankedSchools);
    };

    fetchData();
  }, [subjectsFromCommunity, subjectsFromSubjects, users]);

  const renderAchievementIcon = (points) => {
    if (points >= 1000000) {
      return <i className="fas fa-trophy gold-star"></i>; // Trophy icon for 1M or more points
    } else if (points >= 1000) {
      return <i className="fas fa-star gold-star"></i>; // Star icon for 1000 or more points
    } else if (points >= 100) {
      return <i className="fas fa-seedling gold-star"></i>; // Flower icon for 100 or more points
    } else {
      return null; // No icon for less than 100 points
    }
  };

  const renderLeagueBadge = (league) => {
    switch (league) {
      case "top":
        return <i className="fas fa-medal gold-badge"></i>; // Gold badge for Top League
      case "middle":
        return <i className="fas fa-medal silver-badge"></i>; // Silver badge for Middle League
      case "lower":
        return <i className="fas fa-medal bronze-badge"></i>; // Bronze badge for Lower League
      default:
        return null;
    }
  };

  const toggleDropdown = (index, data, setData) => {
    const updatedData = [...data];
    updatedData[index].showDropdown = !updatedData[index].showDropdown;
    setData(updatedData);
  };

  const renderTable = (data, league) => (
    <table>
      <thead>
        <tr>
          <th>Rank</th>
          <th>Image</th>
          <th>Display Name</th>
          <th>Total Points</th>
        </tr>
      </thead>
      <tbody>
        {data.map((user, index) => (
          <React.Fragment key={index}>
            <tr
              className={index < 5 ? "bg-succes" : ""}
              onClick={() => toggleDropdown(index, data, setLeaderboardData)}
            >
              <td>{user.rank}</td>
              <td>
                {user.photoURL ? (
                  <div className="profile-pics">
                    <img src={user.photoURL} alt="" />
                  </div>
                ) : (
                  <i className="fas fa-user"></i>
                )}
              </td>
              <td className="fw-bolder">
                {user.displayName} {renderAchievementIcon(user.points)}
                {renderLeagueBadge(league)}
                <div className="tex" style={{ fontSize: "0.8rem", color: "#888" }}>
                  {user.school}
                </div>
              </td>
              <td className="fw-bolder text-primary">
                {user.points >= 1000000
                  ? `${(user.points / 1000000).toFixed(2)}M`
                  : user.points >= 1000
                  ? `${(user.points / 1000).toFixed(2)}K`
                  : user.points}
              </td>
            </tr>
            {user.showDropdown && (
              <tr>
                <td colSpan="4">
                  <div>
                    <p>Quizzes Answered: {user.totalCorrectAnswers}</p>
                    <p>Questions Sent: {user.challengesCount}</p>
                    <p>Questions Answered: {user.responsesCount}</p>
                    <p>Questions Reviewed: {user.reviewsCount}</p>                   
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="container my-5">
     
      <div className="mb-4">
        <div className="d-flex ">
              <p className="my-4 btn btn-dark px-3 py-3"> 
                <Link to="/all-quiz-scores" className="nav-link">Quizzes Scores</Link>     
              </p>
              <p className="my-4 mx-3 btn btn-secondary px-3 py-3">     
                <Link to="/leaderboards" className="nav-link">Participation Scores</Link>     
              </p>
              </div>

        {showSchoolRanks && (
          <table>
            <thead>
              <tr>
                <th>Rank</th>
                <th>School</th>
                <th>Total Points</th>
                <th>Top Learner</th>
              </tr>
            </thead>
            <tbody>
              {schoolRanks.map((school, index) => (
                <tr key={index}>
                  <td>{school.rank}</td>
                  <td className="fw-bolder">{school.school}</td>
                  <td className="fw-bolder text-primary">
                    {school.totalPoints >= 1000000
                      ? `${(school.totalPoints / 1000000).toFixed(2)}M`
                      : school.totalPoints >= 1000
                      ? `${(school.totalPoints / 1000).toFixed(2)}K`
                      : school.totalPoints}
                  </td>
                  <td className="fw-bolder text-success">
                    {school.topLearner
                      ? `${school.topLearner.displayName} (${school.topLearner.points})`
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <div className="leagues">
        <p>Top League</p>
        {renderTable(topLeague, "top")}
        <p>Middle League</p>
        {renderTable(middleLeague, "middle")}
        <p>Lower League</p>
        {renderTable(lowerLeague, "lower")}
      </div>
    </div>
  );
};

export default Leaderboards;
