import React, { useContext } from "react";
import SubjectsContext from "./Contexts/SubjectContext";
import image1 from "./images/create-community1.svg";

function CreateSubjects() {
  const values = useContext(SubjectsContext);

  return (
    <>
      {/* Loading Overlay */}
      
      {values?.isCreating && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <div className="spinner"></div>
            <p>Creating Subject, Please Wait...</p>
          </div>
        </div>
      )}


      <div className="container xs-container-fluid mt-5 mb-5">
        <h3>Create Subject</h3>

        <div className="create-community-image-list-container mb-3">
          <div>
            <img src={image1} alt="Create Community" />
          </div>
          <form
          onSubmit={values && values.sendSubject}
          className="w-100 shadow-lg p-2 mb-4 bg-white rounded "
        >
          <input
            style={{ width: "180px" }}
            ref={values.nameRef}
            onChange={(e) => values.setFormValue(e.target.value)}
            className="form-control me-sm-2 m-1 mb-2  mb-sm-0 p-3 w-100"
            type="text"
            placeholder="Subject Name"
          />
          <input
            style={{ width: "180px" }}
            ref={values.descriptionRef}
            className="form-control me-sm-2 m-1 mb-2 mb-sm-0 p-3 w-100"
            type="text"
            placeholder="Topic"
          />
          <input
            style={{ width: "200px" }}
            ref={values.priceRef}
            className="form-control me-sm-2 m-1 mb-2 mb-sm-0 p-2 w-50"
            type="number"
            placeholder="Grade"
          />
          
          <input
            type="file"
            onChange={values.handleFileChange}
            className="form-control-file m-1 mb-2 mb-sm-0 p-2 w-100"
            required
          />
           
          <button
            className="btn btn-dark text-light py-2 px-4 my-2 my-sm-0 p-2"
            type="submit"
            disabled={!values.formValue || values.isCreating}
          >
            {values.isCreating ? "Creating..." : "Create"}
          </button>
        </form>
        </div>


      </div>

      {/* CSS for the loading overlay */}
      <style>
        {`
          .loading-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }

          .loading-popup {
            background: white;
            padding: 20px;
            border-radius: 8px;
            font-size: 18px;
            font-weight: bold;
          }
        `}
      </style>
    </>
  );
}

export default CreateSubjects;
