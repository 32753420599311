import React, { useState, useEffect, useContext } from "react";
import { projectFirestore as firestore } from "../firebase";
import { UsersContext } from "./Contexts/UsersContext";

const QuizScores = ({ grade, subject, students, topic }) => {
  const { users } = useContext(UsersContext);

  const [userScores, setUserScores] = useState([]);

  useEffect(() => {
    const fetchUserScores = async () => {
      try {
        const usersSnapshot = users?.filter((user) =>
          students?.includes(user.displayName)
        );

        const allUserScores = [];

        usersSnapshot.forEach((userDoc) => {
          const userData = userDoc;
          const answeredQuestionsLog = userData.answeredQuestionsLog || [];

          const filteredAnsweredQuestions = answeredQuestionsLog.flatMap(
            (log) =>
              log.quizResults.filter(
                (answeredQuestion) =>
                  answeredQuestion.subject === subject
              )
          );

          const totalAnswered = filteredAnsweredQuestions.length;
          const totalCorrect = filteredAnsweredQuestions.filter(
            (answeredQuestion) => answeredQuestion.isCorrect
          ).length;

          const percentage =
            totalAnswered > 0 ? (totalCorrect / totalAnswered) * 100 : 0;

          allUserScores.push({
            userId: userDoc.id,
            name: userData.displayName,
            photoURL: userData.photoURL,
            school: userData.school,
            totalAnswered,
            totalCorrect,
            percentage,
          });
        });

        setUserScores(allUserScores);
      } catch (error) {
        console.error("Error fetching user scores:", error.message);
      }
    };

    fetchUserScores();
  }, [students, subject]);

  return (
    <div className="my-5">
      <h5 className="my-5 fw-bolder text-primary">Quiz Scores</h5>
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {userScores.map((userScore) => (
            <tr key={userScore.userId}>
              <td>
                {userScore.photoURL ? (
                  <div className="profile-pics">
                    <img src={userScore.photoURL} alt="" />
                  </div>
                ) : (
                  <i className="fas fa-user"></i>
                )}
              </td>
              <td>
                <div className="fw-bolder">{userScore.name}</div>
                <div className="text-primary" style={{ fontSize: "0.8rem", color: "#888" }}>
                  {userScore.school}
                </div>
              </td>
              <td>
                <div>
                  {userScore.totalCorrect}/{userScore.totalAnswered}
                </div>
                <div
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    color:
                      userScore.percentage < 40
                        ? "red"
                        : userScore.percentage < 70
                        ? "blue"
                        : "green",
                  }}
                >
                  {userScore.percentage.toFixed(2)}%
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuizScores;
