import React,{useContext} from "react";
import { projectFirestore} from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import ReactPlayer from "react-player";
import GroupsContext from "./Contexts/GroupsContext";

function PostResponseDisplay({post}) {

    const values = useContext(GroupsContext)
    const getSubject = values.subjects?.filter((sub) => sub.c_id === post);

    const subjectsRef = projectFirestore.collection("route");
    const query = subjectsRef.orderBy("createdAt");
    const [subjects] = useCollectionData(query, { idField: "id" });
    
    const getFileType = (fileUrl) => {
      const extension = fileUrl?.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const audioExtensions = ["mp3", "wav", "ogg"];
      const videoExtensions = ["mp4", "avi", "mkv", "mov"];
      const documentExtensions = [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
      ];
  
      if (imageExtensions.includes(extension)) {
        return "Image";
      } else if (audioExtensions.includes(extension)) {
        return "Audio";
      } else if (videoExtensions.includes(extension)) {
        return "Video";
      } else if (documentExtensions.includes(extension)) {
        return "Document";
      } else {
        return "File";
      }
    };

  return (
    <div className="post-response-display">
       <div>
      <div>
        <div className="row g-0 ">

          <div className="col-lg-9 col-md-12">
            <div id="page-content-wrapper">

              <div className="container-fluid">
                <section className="w-100">
                  <div className="w-100">
                  
                    <div className="w-100 px-0 mx-0 mb-5 h-100">
                      {getSubject &&  
                        getSubject[0].posts?.map((sub) => (
                          <div className="col mb-1 w-100 px-0 mx-0">
                             <div className="card-body p-1">
                                <div className="text-start">
                                  <div className="comment-profile-name">
                                  <div className="comment-profile">
                                    <img src={sub.photoURL  || "https://source.unsplash.com/random/30x30?sig=3"} />
                                  </div>
                                  <p className="px-1">
                                    {sub.displayName && sub.displayName}
                                  </p>
                                  </div>
                                  <p className="text-primary p-1 px-1">
                                  <div dangerouslySetInnerHTML={{ __html: sub.description && sub.description }}></div>
                                    
                                  </p>
                                </div>
                                
                              </div>
                            <div className="w-100">
                              <div className="content-files w-100">
                                {getFileType(sub.file_name1 && sub.file_name1) === "Audio" ? (
                                  <div className="audio my-3 mx-0 p-0">
                                    <audio src={sub.fileUrl1 && sub.fileUrl1} controls />
                                  </div>
                                ) : getFileType(sub.file_name1 && sub.file_name1) === "Video" ? (
                                  <div className="video player-wrapper">
                                    <ReactPlayer
                                      url={sub.fileUrl1 && sub.fileUrl1}
                                      width="100%"
                                      height="100%"
                                      wrapper="div"
                                      className="react-player px-0 mx-0"
                                      controls
                                    />
                                  </div>
                                ) : getFileType(sub.file_name1 && sub.file_name1) === "Image" ? (
                                  <div className="image">
                                    <img src={sub.fileUrl1 && sub.fileUrl1} alt="..." />
                                  </div>
                                ) : getFileType(sub.file_name1 && sub.file_name1) ===
                                  "Document" ? (
                                  <div className="document">
                                    <a
                                      href={sub.fileUrl1 && sub.fileUrl1}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {sub.file_name1 && sub.file_name1}
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <p></p>
                                  </div>
                                )}
                              </div>
                             
                             
                            </div>
                          </div>
                        ))}
                     
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default PostResponseDisplay
