import React, { useState, useContext,useEffect } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import CommunitiesContext from "./Contexts/CommunityContext";
import image1 from "./images/create-community1.svg"
import subject_img from "./subject.svg"
import { useIntersectionObserver } from './useIntersectionObserver';

function DisplayUserCommunities() {
  const values = useContext(CommunitiesContext);
  const [user] = useAuthState(auth);
  const [dropdownOpen, setDropdownOpen] = useState({}); // Track dropdown state for each subject
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  const toggleDropdown = (subjectId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [subjectId]: !prevState[subjectId],
    }));
  };

  const userSubjects =
    values.subjects &&
    values.subjects.filter((subject) => subject.uid === user.uid);


    const setVisible = (element) => {
      element.classList.add('visible');
    };
  
    useIntersectionObserver(setVisible);
    
  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }} className="mt-5 mb-5">
        <div className="create-community-image-list-container mt-1 mb-1">
          
          {userSubjects && userSubjects.length > 0 ? (
            <ul class="my-list">
              <li>Below are the groups you created.</li>
            </ul>
          ) : (
            <p>You have not created any groups</p>
          )}
          <div>
            <img src={image1} />
          </div>
        </div>
        <section className="py-5">
          <div>
            <div className="subjects">
              {userSubjects &&
                userSubjects.map((subject, index) => (
                  <div className=" mb-5 subject" key={index}>
                    <div>
                      <Link

                        to={`/display-communities/${subject.name}`}
                        state={{ data: subject }}
                        style={{ textDecoration: "none" }}
                        className="col mb-2"
                      >
                        <div>
                          <img
                            className="subject-img"
                            src={
                              subject.file
                                ? subject.file.url
                                : subject_img
                            }
                            alt="..."
                          />

                          <div className="subjects-cont text-light bg-dark px-2">
                            <div>
                              <p className="fw-bolder">{subject.name}</p>
                              {subject.students && (
                                <p>{subject.students.length} students</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DisplayUserCommunities;
