import React, { useRef, useState } from "react";
import { projectFirestore, auth } from "../firebase";
import firebase from "firebase/compat/app";
import DisplayLesson from "./DisplayLesson";
import { v4 } from "uuid";
import AddContent from "./AddContent";
import CreateQuizQuestion from "./CreateQuizQuestion";

function CreateLesson({ subject, path }) {
  const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);
  const [isAddContentModalOpen, setIsAddContentModalOpen] = useState(false);

  const route = `${path}/lesson/l`;

  const dummy = useRef();
  const nameRef = useRef();
  const descriptionRef = useRef();
  const subjectsRef = projectFirestore.collection(route);

  const sendSubject = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;
    const l_id = v4();
    const subjectName = nameRef.current.value;
    if (subjectName === "") {
      alert("Enter the name");
      return;
    }
    const querySnapshot = await subjectsRef.where("name", "==", subjectName).get();

    if (!querySnapshot.empty) {
      alert("Name already exists, try another name");
      return;
    }

    const newSubject = {
      name: subjectName,
      description: descriptionRef.current.value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      l_id,
      photoURL,
    };

    await subjectsRef.add(newSubject);

    dummy.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleOpenQuizModal = () => setIsQuizModalOpen(true);
  const handleCloseQuizModal = () => setIsQuizModalOpen(false);

  const handleOpenAddContentModal = () => setIsAddContentModalOpen(true);
  const handleCloseAddContentModal = () => setIsAddContentModalOpen(false);

  return (
    <div className="container xs-container-fluid  mt-5 mb-5">
      <div className="d-flex gap-3 mt-5 mb-5">
      <button
        onClick={handleOpenAddContentModal}
        className="btn btn-dark px-4 py-4 text-light"
      >
        Create Class
      </button>

      {isAddContentModalOpen && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Class Content</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseAddContentModal}
                ></button>
              </div>
              <div className="modal-body">
                <AddContent subjectId={subject} />
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={handleCloseAddContentModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={handleOpenQuizModal}
        className="btn btn-secondary px-4 py-4 text-light"
      >
        Create Quiz
      </button>

      {isQuizModalOpen && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Quiz</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseQuizModal}
                ></button>
              </div>
              <div className="modal-body">
                <CreateQuizQuestion
                  lesson_id={"quiz"}
                  subject={subject}
                  type={"subject"}
                />
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={handleCloseQuizModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
      <hr />
      <main className="my-5 subject-wrapper">
        <DisplayLesson subject={subject} route={route} />
        <span ref={dummy}></span>
      </main>
    </div>
  );
}

export default CreateLesson;
