import React,{useEffect} from 'react';
import home from "./home.jpg";
import home2 from "./home2.svg"
import home4 from "./home4.svg"
import { Link } from "react-router-dom";
import HomeContent from "./HomeContent";
import InstallButton from "./InstallButton";

function Home() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <div className="home-wrapper">
      <div className="image-container">
        <img src={home4} alt="Background" className="background-image" />
        <div className="overlay"></div>
        <div className="home-content">
          <div>
            <h2>Online School For Grade 12 Learners <h1  className="text-success">Scul</h1></h2>
            <p className="text-p">Discover, Engage, and Excel in Every Subject!</p>
            <Link
              className="btn btn-primary px-5 py-3  btn-animate"
              to="/display-subjects"
              style={{ textDecoration: "none" }}
            >
              View Subjects
            </Link>
            <InstallButton />
          </div>
        </div>
      </div>

      <div  className="container xs-container-fluid text-start mt-5 mb-5 small-screen-content">
        <h2>Online School For Grade 12 Learners <span className="text-success">Scul</span></h2>
        <p>Discover, Engage, and Excel in Every Subject!</p>
        
        <Link
          className="btn btn-primary px-5 py-3 btn-animate"
          to="/display-subjects"
          style={{ textDecoration: "none"}}
        >
          View Subjects
        </Link>
        <InstallButton />
      </div>
        <HomeContent />
    </div>
  );
}

export default Home;
