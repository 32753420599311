import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { projectStorage, projectFirestore as db } from "../firebase";

export default function InstructorSignup() {
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [photoFile, setPhotoFile] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

      if (imageExtensions.includes(extension)) {
        setPhotoFile(file);
      } else {
        setError("Please select a valid image file (jpg, jpeg, png, gif)");
      }
    } else {
      setError("Please select an image");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);

      const usernameExists = await checkUsernameExists(usernameRef.current.value);
      if (usernameExists) {
        setLoading(false);
        return setError("Username already exists. Please choose another one.");
      }

      const { user } = await firebase.auth().createUserWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      );
      await user.updateProfile({
        displayName: usernameRef.current.value,
      });

      let photoUrl = "";
      if (photoFile) {
        const storageRef = projectStorage.ref();
        const fileRef = storageRef.child(photoFile.name);
        await fileRef.put(photoFile);
        photoUrl = await fileRef.getDownloadURL();
      }

      await db.collection("usersCollection").doc("usersDoc").update({
        users: firebase.firestore.FieldValue.arrayUnion({
          uid: user.uid,
          displayName: usernameRef.current.value,
          email: emailRef.current.value,
          bio: "",
          education: "",
          modules: selectedModules,
          is_instructor: true,
          photoURL: photoUrl,
          answeredQuestionsLog: [],
          paymentDetails: null,
          lastPaymentMonth: null,
        }),
      });

      alert("Signed up successfully");
      navigate("/");
      window.location.reload();
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  }

  async function checkUsernameExists(username) {
    try {
      const snapshot = await db.collection("usersCollection").doc("usersDoc").get();
      const users = snapshot.data()?.users || [];
      return users.some((user) => user.displayName === username);
    } catch (err) {
      console.error("Error checking username", err);
      setError("Error checking username. " + err.message);
      return false;
    }
  }

  const handleModuleChange = (e) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedModules(selectedValues);
  };

  return (
    <div className="container xs-container-fluid mt-5 mb-5">
      <section className="mb-5 mt-5">
        <div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-xl-11">
              <div className="text-black">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                      <p className="text-center fw-bolder">Sign up</p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              ref={usernameRef}
                              required
                              type="text"
                              id="username"
                              className="form-control"
                            />
                            <label className="form-label" htmlFor="username">
                              Username
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="email"
                              id="email"
                              className="form-control"
                              ref={emailRef}
                              required
                            />
                            <label className="form-label" htmlFor="email">
                              Email
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="password"
                              className="form-control"
                              ref={passwordRef}
                              required
                            />
                            <label className="form-label" htmlFor="password">
                              Password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="password-confirm"
                              className="form-control"
                              ref={passwordConfirmRef}
                              required
                            />
                            <label className="form-label" htmlFor="password-confirm">
                              Repeat password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-book fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <select
                              multiple
                              className="form-control"
                              id="modules"
                              onChange={handleModuleChange}
                            >
                              <option value="Mathematics">Mathematics</option>
                              <option value="Mathematical Literacy">Mathematical Literacy</option>
                              <option value="Life Sciences">Life Sciences</option>
                              <option value="Life Sciences">physical Sciences</option>
                              <option value="Geography">Geography</option>
                              <option value="Accounting">Accounting</option>
                              <option value="Business Studies">Business Studies</option>
                              <option value="Economics">Economics</option>
                              <option value="English">English</option>
                              <option value="Other">Other</option>
                            </select>
                            <label className="form-label" htmlFor="modules">
                              Subjects To Teach
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-image fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="file"
                              id="photoUrl"
                              className="form-control"
                              onChange={handlePhotoUpload}
                              required
                            />
                            <label className="form-label" htmlFor="photoUrl">
                              Profile Picture
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <Button type="submit" className="btn btn-primary w-75" disabled={loading}>
                            {loading ? "Loading..." : "Register"}
                          </Button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                        className="img-fluid"
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}














/*import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { projectStorage as storage } from "../firebase";

export default function InstructorSignup() {
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [photoFile, setPhotoFile] = useState(null); // Store the selected file
  const [selectedModules, setSelectedModules] = useState([]);

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

      if (imageExtensions.includes(extension)) {
        setPhotoFile(file);
      } else {
        setError("Please select a valid image file (jpg, jpeg, png, gif)");
      }
    } else {
      setError("Please select an image");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
  
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }
  
    try {
      setError("");
      setLoading(true);
  
      const usernameExists = await checkUsernameExists(usernameRef.current.value);
      if (usernameExists) {
        setLoading(false);
        return setError("Username already exists. Please choose another one.");
      }
  
      const { user } = await firebase.auth().createUserWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      );
      await user.updateProfile({
        displayName: usernameRef.current.value
      });

      // Upload the photo and get the URL
      let photoUrl = "";
      if (photoFile) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(photoFile.name);
        await fileRef.put(photoFile);
        photoUrl = await fileRef.getDownloadURL();
      }

  
      // Update user document in usersDoc
      await firebase.firestore().collection("usersCollection").doc("usersDoc").update({
        users: firebase.firestore.FieldValue.arrayUnion({
          uid: user.uid,
          displayName: usernameRef.current.value,
          email: emailRef.current.value,
          bio: "",
          education: "",
          modules: selectedModules,
          is_instructor: true,
          photoURL: photoUrl,
          answeredQuestionsLog: [],
        }),
      });
  
      alert("Signed up successfully");
      navigate("/");
      window.location.reload(); // Reload the page after navigating to home
    } catch {
      setError("Failed to create an account");
    }
  
    setLoading(false);
  }

  async function checkUsernameExists(username) {
    try {
      const snapshot = await firebase.firestore().collection("usersCollection").doc("usersDoc").get();
      const users = snapshot.data()?.users || [];
      return users.some(user => user.displayName === username);
    } catch (err) {
      console.error("Error checking username", err);
      setError("Error checking username. " + err.message);
      return false;
    }
  }

  const handleModuleChange = (e) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedModules(selectedValues);
  };

  return (
    <div className="container xs-container-fluid mt-5 mb-5">
      <section className="mb-5 mt-5">
        <div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-xl-11">
              <div className="text-black">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                      <p className="text-center fw-bolder">Sign up</p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              ref={usernameRef}
                              required
                              type="text"
                              id="username"
                              className="form-control"
                            />
                            <label className="form-label" htmlFor="username">
                              Username
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="email"
                              id="email"
                              className="form-control"
                              ref={emailRef}
                              required
                            />
                            <label className="form-label" htmlFor="email">
                              Email
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="password"
                              className="form-control"
                              ref={passwordRef}
                              required
                            />
                            <label className="form-label" htmlFor="password">
                              Password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="password-confirm"
                              className="form-control"
                              ref={passwordConfirmRef}
                              required
                            />
                            <label className="form-label" htmlFor="password-confirm">
                              Repeat password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-book fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <select
                              multiple
                              className="form-control"
                              id="modules"
                              onChange={handleModuleChange}
                            >
                              <option value="Mathematics">Mathematics</option>
                              <option value="Mathematical Literacy">Mathematical Literacy</option>
                              <option value="Life Sciences">Life Sciences</option>
                              <option value="Geography">Geography</option>
                              <option value="Accounting">Accounting</option>
                              <option value="Business Studies">Business Studies</option>
                              <option value="Economics">Economics</option>
                              
                              {/* Add more subjects here *//*}
                            </select>
                            <label className="form-label" htmlFor="modules">
                              Subjects To Teach
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-image fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="file"
                              id="photoUrl"
                              className="form-control"
                              onChange={handlePhotoUpload}
                              required
                            />
                            <label className="form-label" htmlFor="photoUrl">
                              Profile Picture
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <Button type="submit" className="btn btn-primary w-75" disabled={loading}>
                            {loading ? "Loading..." : "Register"}
                          </Button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                        className="img-fluid"
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
*/