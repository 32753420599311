import React, { useState } from "react";
import { Card, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import UserData from "./UserData";
import PayPalButton from "./PayPalButton";
import firebase from "firebase/compat/app";
import { projectFirestore as db } from "../firebase";

export default function Dashboard() {
  const [error, setError] = useState("");
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  async function handlePaymentSuccess(details) {
    try {
      const currentMonth = new Date().toLocaleString('default', { month: 'long' });
      const userRef = db.collection("usersCollection").doc("usersDoc");
      const userDoc = await userRef.get();
      const users = userDoc.data()?.users || [];

      const updatedUsers = users.map(user => {
        if (user.uid === currentUser.uid) {
          // Check if user has already paid this month
          if (user.lastPaymentMonth === currentMonth) {
            throw new Error("Payment already made for this month.");
          }

          // Update payment details and last payment month
          return { 
            ...user, 
            paymentDetails: details,
            lastPaymentMonth: currentMonth
          };
        }
        return user;
      });

      await userRef.update({
        users: updatedUsers
      });

      // Update user bio attribute
      await db.collection("usersCollection").doc(currentUser.uid).update({
        bio: ""
      });

      alert("Payment successful for " + currentMonth + "!");
    } catch (err) {
      console.error("Error updating payment details", err);
      setError("Error updating payment details. " + err.message);
    }
  }

  return (
    <div className="container mt-5 mb-5">
      <UserData />
      <div className="my-5 mx-2">
        <div>
          
          {error && <Alert variant="danger">{error}</Alert>}
          

          <Link to="/update-profile" className="btn btn-primary w-50 mt-3">
            Update Profile
          </Link>
        </div>
      </div>
      {/* 
      <div className="d-flex justify-content-center my-5 mx-2">
        <PayPalButton
          amount="10.00"
          onSuccess={(details, data) => handlePaymentSuccess(details)}
        />
      </div>
      */}
      <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </div>
  );
}
