import React, { useEffect, useState } from "react";
import axios from "axios";

const OpenTriviaQuiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(5);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://opentdb.com/api_category.php"
        );
        setCategories(response.data.trivia_categories);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCategories();
  }, []);
  const shuffleAnswers = (answers) => {
    const shuffledAnswers = [...answers];
    for (let i = shuffledAnswers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledAnswers[i], shuffledAnswers[j]] = [
        shuffledAnswers[j],
        shuffledAnswers[i],
      ];
    }
    return shuffledAnswers;
  };
  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://opentdb.com/api.php?amount=${numberOfQuestions}&category=${selectedCategory}&type=multiple`
        );
        setQuestions(response.data.results);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    };

    fetchQuestions();
  }, [numberOfQuestions, selectedCategory]);

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    } else {
      setShowResult(true);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleNumberOfQuestionsChange = (event) => {
    setNumberOfQuestions(event.target.value);
  };

  const handleRestart = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
  };

  const handleClose = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (showResult) {
    return (
      <div className="m-4">
        <h3 className="my-3">Final Score : {score}</h3>
        
        {questions.map((question, index) => (
          <div className="my-4" key={index}>
            <h6 >{question.question}</h6>
            <p className="text-success">Correct Answer: {question.correct_answer}</p>
          </div>
        ))}
        <button className="btn btn-danger my-2 text-light" onClick={handleClose}>Close</button>
      </div>
    );
  }

  return (
    <div className="open-trivia-quiz">
     
      
          <div className="choose-category">
            <h4>Choose a Category</h4>
            <select
              className="form-select"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">Any Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
      

      <div className="choose-questions">
        <h4>Choose the Number of Questions</h4>
        <select
          value={numberOfQuestions}
          onChange={handleNumberOfQuestionsChange}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>

      <div className="trivia-quiz-questions">
        <div>
          <h2>Quiz</h2>
          <p>Question {currentQuestion + 1}</p>
          <h5 className="text-success">
            {questions[currentQuestion].question}
          </h5>
          <ul>
            {shuffleAnswers([
              ...questions[currentQuestion].incorrect_answers,
              questions[currentQuestion].correct_answer,
            ]).map((answer, index) => (
              <li key={index}>
                <button
                  className="btn btn-secondary text-light answer-button my-3"
                  onClick={() => handleAnswer(answer === questions[currentQuestion].correct_answer)}
                >
                  {answer}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OpenTriviaQuiz;
