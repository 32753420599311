import React, { useState, useEffect, useContext } from "react";
import { UsersContext } from "./Contexts/UsersContext";
import { projectFirestore as firestore } from "../firebase";
import {Link} from "react-router-dom"
const AllUsersQuizScores = () => {
  const { users } = useContext(UsersContext);
  const [usersScores, setUsersScores] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("All");
  const [rankBy, setRankBy] = useState("totalCorrect");
  const [availableSubjects, setAvailableSubjects] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  useEffect(() => {
    const allUsersScores = [];
    const subjectsSet = new Set();
    users.forEach((userData) => {
      const answeredQuestionsLog = userData.answeredQuestionsLog || [];
      const allQuizResults = answeredQuestionsLog.flatMap(
        (log) => log.quizResults
      );

      const subjectGroups = {};

      allQuizResults.forEach((quizResult) => {
        const { subject, isCorrect } = quizResult;

        subjectsSet.add(subject);

        if (selectedSubject === "All" || subject === selectedSubject) {
          if (!subjectGroups[subject]) {
            subjectGroups[subject] = {
              totalAnswered: 0,
              totalCorrect: 0,
            };
          }

          subjectGroups[subject].totalAnswered++;
          if (isCorrect) {
            subjectGroups[subject].totalCorrect++;
          }
        }
      });

      let highestSubject = { subject: "N/A", averagePercentage: 0 };

      Object.keys(subjectGroups).forEach((subject) => {
        const { totalAnswered, totalCorrect } = subjectGroups[subject];
        const averagePercentage =
          totalAnswered > 0 ? (totalCorrect / totalAnswered) * 100 : 0;

        if (averagePercentage > highestSubject.averagePercentage) {
          highestSubject = {
            subject,
            averagePercentage,
          };
        }
      });

      const totalAnswered = allQuizResults.length;
      const totalCorrect = allQuizResults.filter(
        (quizResult) => quizResult.isCorrect
      ).length;
      const averagePercentage =
        totalAnswered > 0 ? (totalCorrect / totalAnswered) * 100 : 0;

      allUsersScores.push({
        userId: userData.displayName,
        photoURL: userData.photoURL,
        totalAnswered,
        totalCorrect,
        school: userData.school,
        averagePercentage,
        highestSubject,
      });
    });

    // Sort users based on selected ranking criteria
    allUsersScores.sort((a, b) => {
      if (rankBy === "averagePercentage") {
        return b.averagePercentage - a.averagePercentage;
      } else if (rankBy === "totalCorrect") {
        return b.totalCorrect - a.totalCorrect;
      }
      return 0;
    });

    setUsersScores(allUsersScores);
    setAvailableSubjects(Array.from(subjectsSet));
  }, [selectedSubject, rankBy, users]);

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleRankByChange = (e) => {
    setRankBy(e.target.value);
  };

  return (
    <div className="my-5 container container-fluid">
      <div className="d-flex ">
      <p className="my-4 btn btn-secondary px-3 py-3"> 
        <Link to="/all-quiz-scores" className="nav-link">Quizzes Scores</Link>     
      </p>
      <p className="my-4 mx-3 btn btn-dark px-3 py-3">     
        <Link to="/leaderboards" className="nav-link">Participation Scores</Link>     
      </p>
      </div>
      <div className="my-5">
        <label htmlFor="subjectSelect" className="me-2 mt-3 fw-bolder">
          Select Subject:
        </label>
        <select
          style={{ maxWidth: "300px" }}
          className="mb-3"
          id="subjectSelect"
          onChange={handleSubjectChange}
          value={selectedSubject}
        >
          <option value="All">All Subjects</option>
          {availableSubjects.map((subject, index) => (
            <option key={index} value={subject}>
              {subject}
            </option>
          ))}
        </select>

        <label htmlFor="rankBySelect" className="ms-4 me-2 mt-3 fw-bolder">
          Rank By:
        </label>
        <select
          style={{ maxWidth: "300px" }}
          className="mb-5"
          id="rankBySelect"
          onChange={handleRankByChange}
          value={rankBy}
        >
          <option value="averagePercentage">Average Percentage</option>
          <option value="totalCorrect">Most Correct Answers</option>
        </select>
      </div>
      <p className="text-info my-2">
        Complete quizzes in Subjects and Groups to get scores
      </p>
      <table style={{ width: "100%", overflowX: "scroll" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Scores</th>
          </tr>
        </thead>
        <tbody>
          {usersScores
            .filter(
              (userScore) =>
                selectedSubject === "All" ||
                userScore.highestSubject.subject === selectedSubject
            ).map((userScore, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  {userScore.photoURL ? (
                    <div className="profile-pics">
                      <img src={userScore.photoURL} alt="" />
                    </div>
                  ) : (
                    <i className="fas fa-user"></i>
                  )}
                </td>
                <td>
                  <div>{userScore.userId}</div>
                  <div style={{ fontSize: "0.8rem", color: "#888" }}>
                    {userScore.school}
                  </div>
                </td>
                <td>
                  <div>{`${userScore.totalCorrect}/${userScore.totalAnswered}`}</div>
                  <div
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      color:
                        userScore.averagePercentage > 80
                          ? "green"
                          : userScore.averagePercentage > 50
                          ? "blue"
                          : userScore.averagePercentage > 30
                          ? "orange"
                          : "red",
                    }}
                  >
                    {userScore.averagePercentage.toFixed(2)}%
                  </div>
                  <div style={{ fontSize: "0.8rem", color: "#888" }}>
                    {userScore.highestSubject.subject} (
                    {userScore.highestSubject.averagePercentage.toFixed(2)}%)
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllUsersQuizScores;
