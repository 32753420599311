import React from "react";

function StarIcons({ averageRating }) {
  const filledStars = Math.floor(averageRating);
  const hasHalfStar = averageRating - filledStars >= 0.5;

  const stars = [];

  for (let i = 0; i < filledStars; i++) {
    stars.push(<i className="fas fa-star" key={i}></i>);
  }
  if (hasHalfStar) {
    stars.push(<i className="fas fa-star-half-alt" key={filledStars}></i>);
  }
  while (stars.length < 5) {
    stars.push(<i className="far fa-star" key={stars.length}></i>);
  }

  return <div className="star-icons">{stars}</div>;
}

export default StarIcons;
