import React, { useContext, useState } from "react";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

function CreateChallenges({ subjectId, type }) {
  let cont = type === "community" ? CommunityContext : SubjectsContext;
  const values = useContext(cont);

  const [isCreating, setIsCreating] = useState(false);
  const [lesson, setLesson] = useState([]);
  const l_id = v4();
  const [file_name1, setFileName1] = useState("");

  // State for subject and topics
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");

  const [lessonAttributes, setLessonAttributes] = useState({
    topic: "",
    description: "",
    l_id,
    summary: "",
    file1: null,
    file_name1,
  });

  // Subjects and Topics Mapping
  const subjectsWithTopics = {
    Mathematics: ["Algebra", "Euclidean Geometry", "Calculus", "Trigonometry","Number pattens","Analytical Geometry", "Finance", "Probability","Functions","Statistics","Other"],
    "Life Sciences": ["DNA: THE CODE OF LIFE", "REPRODUCTION", "GENETICS AND INHERITANCE", "RESPONDING TO THE ENVIRONMENT", "EVOLUTION","Other"],
    PhysicalSciences: ["MECHANICS", "MATTER & MATERIALS", "CHEMICAL CHANGE", "ELECTRICITY & MAGNETISM","Other"],
    Geography: ["CLIMATE AND WEATHER", "GEOMORPHOLOGY", "SETTLEMENT GEOGRAPHY"],
    Accounting: ["COMPANIES","INVENTORY VALUATION", "FIXED ASSETS, INTERNAL CONTROL", "COST ACCOUNTING","RECONCILIATIONS", "VAT", "Budgeting", "Other"],
    Business: ["IMPACTS OF RECENT LEGISLATION","HUMAN RESOURCES FUNCTION", "PROFESSIONALISM & ETHICS", "CREATIVE THINKING & PROBLEM SOLVING","MACRO-ENVIRONMENT: BUSINESS STRATEGIES", "BUSINESS SECTORS & THEIR ENVIRONMENTS", "QUALITY OF PERFORMANCE , MANAGEMENT & LEADERSHIP","INVESTMENT","HUMAN RIGHTS ,SOCIAL RESPONSIBILITY", "FORMS OF OWNERSHIP ", "Other"],
    Other:["Other"]
  };

  const handleLessonAttributeChange = (value, attributeName) => {
    setLessonAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: value,
    }));
  };

  const handleFileChange1 = (e) => {
    const file1 = e.target.files[0];
    if (file1 && file1.type.startsWith("image/")) {
      const fileName = file1.name;
      setFileName1(fileName);
      setLessonAttributes({ ...lessonAttributes, file1, file_name1: fileName });
    } else {
      alert("Please upload an image file.");
    }
  };

  const handleLessonSave = async () => {
    if (!selectedSubject) {
      alert("Please select a subject.");
      return;
    }
    if (!selectedTopic) {
      alert("Please select a topic.");
      return;
    }
    if (lessonAttributes.description.trim() === "" && !lessonAttributes.file1) {
      alert("Write your question or upload an image.");
      return;
    }
  
    setIsCreating(true);
  
    try {
      // Ensure lesson is updated before calling the function
      const updatedLesson = [...lesson, ""];
  
      await values.addChallengeToSubject(updatedLesson, subjectId, {
        ...lessonAttributes,
        topic: selectedTopic,
        file1: lessonAttributes.file1, // Ensure file is passed
      });
  
      // Clear form after successful submission
      setLesson([]);
      setLessonAttributes({
        topic: "",
        description: "",
        summary: "",
        file1: null,
      });
      setFileName1("");
      setSelectedSubject("");
      setSelectedTopic("");
      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      alert("Error adding content:", error.message);
    }
  };
  
  

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <div className="my-1">
      <h4 className="text-success my-3 fw-bolder">Send Question</h4>
      {isCreating && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <div className="spinner"></div>
            <p>Sending, Please Wait...</p>
          </div>
        </div>
      )}

      {/* Subject Selection */}
      <select
      style={{maxWidth:"400px"}}
        className="form-control my-2"
        value={selectedSubject}
        onChange={(e) => {
          setSelectedSubject(e.target.value);
          setSelectedTopic(""); // Reset topic when subject changes
        }}
      >
        <option value="">Select Subject</option>
        {Object.keys(subjectsWithTopics).map((subject) => (
          <option key={subject} value={subject}>
            {subject}
          </option>
        ))}
      </select>

      {/* Topic Selection */}
      <select
      style={{maxWidth:"400px"}}
        className="form-control my-2"
        value={selectedTopic}
        onChange={(e) => setSelectedTopic(e.target.value)}
        disabled={!selectedSubject} // Disable if no subject selected
      >
        <option value="">Select Topic</option>
        {selectedSubject &&
          subjectsWithTopics[selectedSubject].map((topic) => (
            <option key={topic} value={topic}>
              {topic}
            </option>
          ))}
      </select>

      {/* ReactQuill for Question */}
      <ReactQuill
        name="description"
        value={lessonAttributes.description}
        onChange={(e) => handleLessonAttributeChange(e, "description")}
        placeholder="Write Question"
        className="react-quill mt-2 mb-3"
        modules={modules}
      />

      {/* File Input for Image */}
      <div className="mb-3">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange1}
          className="form-control"
        />
        {file_name1 && <p>Selected File: {file_name1}</p>}
      </div>

      <button
        className="btn btn-success text-light my-2"
        onClick={handleLessonSave}
        disabled={isCreating}
      >
        {isCreating ? "Sending..." : "Submit"}
      </button>
    </div>
  );
}

export default CreateChallenges;
