import React from "react";
import { useParams, useLocation } from "react-router-dom";
import CreateContent from "./CreateContent";

function UserLesson() {
  const { lesson } = useParams();
  const createQuiz = true;
  const location = useLocation();
  const { data } = location.state;
  return (
    <div className="mt-5 mb-1">
      <CreateContent lesson_info={data} lesson_id={lesson} createQuiz={createQuiz} path={`${lesson}/content/c`} />
    </div>
  );
}

export default UserLesson;
