import React, { useContext, useState } from "react";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ChallengesSubmission = ({ subjectName, challengeId, type }) => {
  let cont = type === "community" ? CommunityContext : SubjectsContext;
  const values = useContext(cont);

  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);

      // Show preview if it's an image
      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => setFilePreview(reader.result);
        reader.readAsDataURL(selectedFile);
      } else {
        setFilePreview(null);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!summary) {
      alert("Please fill in your answer.");
      return;
    }

    setLoading(true);

    const responseAttributes = {
      file: file ? file : "",
      
      summary,
    };

    await values.addResponseToChallenge(subjectName, challengeId, responseAttributes);

    setFile(null);
    setFilePreview(null);
    setSummary("");
    setLoading(false);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <div>
      <h6 className="fw-bolder text-success">Send your answer</h6>
      <form onSubmit={handleSubmit}>
        {/* Answer Input */}
        <ReactQuill
          value={summary}
          onChange={setSummary}
          placeholder="Write your answer..."
          modules={modules}
          className="my-3"
        />

        {/* File Input */}
        <input
          type="file"
          className="form-control my-3"
          accept="image/*, .pdf, .doc, .docx"
          onChange={handleFileChange}
        />

        {/* Image Preview */}
        {filePreview && (
          <div className="my-3">
            <p className="fw-bold text-primary">Image Preview:</p>
            <img src={filePreview} alt="Preview" className="img-fluid rounded" style={{ maxHeight: "200px" }} />
          </div>
        )}

        {/* Submit Button */}
        {loading ? (
          <button className="mt-4 btn btn-success py-2 px-2" disabled>Sending...</button>
        ) : (
          <button className="mt-4 btn btn-success py-2 px-2" type="submit">Send Your Answer</button>
        )}
      </form>
    </div>
  );
};

export default ChallengesSubmission;
