import React, { useContext, useState } from "react";
import SubjectsContext from "./Contexts/SubjectContext";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

function AddContent({ subjectId }) {
  const values = useContext(SubjectsContext);
  const the_subject = values.subjects.filter(
    (subject) => subject.name === subjectId
  );

  const [isCreating, setIsCreating] = useState(false);
  const [createClass, setCreateClass] = useState(true);
  const [addLearningOutcomes, setAddLearningOutcomes] = useState(false);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [learningOutcome, setLearningOutcome] = useState("");
  const [courseStructure, setCourseStructure] = useState("");
  const [lesson, setLesson] = useState([]);
  const l_id = v4();
  const [file_name1, setFileName1] = useState("");
  const [file_name2, setFileName2] = useState("");
  const [file_name3, setFileName3] = useState("");
  const [file_name4, setFileName4] = useState("");
  const [file_name5, setFileName5] = useState("");

  const [fileInfo1, setFileInfo1] = useState("");
  const [fileInfo2, setFileInfo2] = useState("");
  const [fileInfo3, setFileInfo3] = useState("");
  const [fileInfo4, setFileInfo4] = useState("");
  const [fileInfo5, setFileInfo5] = useState("");

  const [lessonAttributes, setLessonAttributes] = useState({
    topic: "",
    description: "",
    l_id,
    summary: "",
    file1: null,
    file_name1,
    file2: null,
    file_name2,
    file3: null,
    file_name3,
    file4: null,
    file_name4,
    file5: null,
    file_name5,
  });

  const FILE_SIZE_LIMIT = 60 * 1024 * 1024;

  const handleAddLearningOutcome = () => {
    if (learningOutcome) {
      setLearningOutcomes([learningOutcome]);
    }
  };

  const handleAddLesson = () => {
    setLesson([...lesson, ""]);
  };

  const handleLessonAttributeChange = (value, attributeName) => {
    setLessonAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: value,
    }));
  };

  const handleFileChange = (e, fileIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > FILE_SIZE_LIMIT) {
      alert("File size exceeds the 60 MB limit. Please upload a smaller file.");
      return;
    }

    const fileName = file.name;
    const fileInfoSetter = {
      1: setFileInfo1,
      2: setFileInfo2,
      3: setFileInfo3,
      4: setFileInfo4,
      5: setFileInfo5,
    }[fileIndex];

    const fileNameSetter = {
      1: setFileName1,
      2: setFileName2,
      3: setFileName3,
      4: setFileName4,
      5: setFileName5,
    }[fileIndex];

    fileInfoSetter(fileName);
    fileNameSetter(fileName);
    setLessonAttributes((prev) => ({
      ...prev,
      [`file${fileIndex}`]: file,
      [`file_name${fileIndex}`]: fileName,
    }));
  };

  const handleSave = async () => {
    handleAddLearningOutcome();
    if (learningOutcomes.length === 0) {
      return;
    }
    setIsCreating(true);

    try {
      await values.addContentToSubject(
        learningOutcomes,
        courseStructure,
        subjectId
      );

      setLearningOutcome("");
      setCourseStructure([]);
      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      alert("Error adding content:", error.message);
    }
  };

  const handleLessonSave = async () => {
    if (lessonAttributes.topic.length < 1) {
      alert("Class Topic cannot be empty");
      return;
    }

    if (lessonAttributes.summary.length < 1) {
      alert("Class content cannot be empty");
      return;
    }

    handleAddLesson();
    if (lesson.length === 0) {
      return;
    }
    setIsCreating(true);

    try {
      await values.addLessonToSubject(lesson, subjectId, lessonAttributes);

      setLesson([]);
      setLessonAttributes({
        topic: "",
        description: "",
        summary: "",
        file1: null,
        file2: null,
        file3: null,
        file4: null,
        file5: null,
      });

      setFileName1("");
      setFileName2("");
      setFileName3("");
      setFileName4("");
      setFileName5("");

      setIsCreating(false);
      alert("Created Class")
    } catch (error) {
      setIsCreating(false);
      alert("Error adding class:", error.message);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ["italic"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ align: [] }],
        ["code-block"],
        ["clean"],
      ],
    },
  };

  return (
    <div className="my-1">
      {/* Loading Popup */}
      {isCreating && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <div className="spinner"></div>
            <p>Creating Class, Please Wait...</p>
          </div>
        </div>
      )}

      {!createClass ? (
        <button className="btn btn-success" onClick={() => setCreateClass(true)}>
          Create Class
        </button>
      ) : (
        <div className="mt-1">
          <h6 className="text-primary fw-bolder">Write Topic</h6>
          <ReactQuill
            name="topic"
            value={lessonAttributes.topic}
            onChange={(e) => handleLessonAttributeChange(e, "topic")}
            placeholder="Topic"
            className="react-quill m-1 me-2 my-5"
          />

          <h6 className="text-success fw-bolder">Write Description</h6>
          <ReactQuill
            name="description"
            value={lessonAttributes.description}
            onChange={(e) => handleLessonAttributeChange(e, "description")}
            placeholder="Description"
            className="react-quill m-1 me-2 my-5"
          />

          <h6 className="text-primary fw-bolder">Write Class Content</h6>
          <ReactQuill
            name="summary"
            value={lessonAttributes.summary}
            onChange={(e) => handleLessonAttributeChange(e, "summary")}
            placeholder="Class Content"
            className="react-quill m-1 me-2 my-5"
            modules={modules}
            style={{ height: "400px", minHeight: "40px" }}
          />

          <h6>Upload Class Files</h6>
          <p>Files may include videos, audio, documents, and/or pictures (Max: 60MB)</p>

          {[1, 2, 3, 4, 5].map((index) => (
            <div className="my-2" key={index}>
              <input type="file" onChange={(e) => handleFileChange(e, index)} />
              {eval(`fileInfo${index}`) && <p>Selected file: {eval(`fileInfo${index}`)}</p>}
            </div>
          ))}

          <button className="btn btn-success m-3" onClick={handleLessonSave} disabled={isCreating}>
            {isCreating ? "Creating..." : "Click Twice To Save Class"}
          </button>
        </div>
      )}

      <style>
        {`
          .loading-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
          }

          .loading-popup {
            background: #fff;
            padding: 30px;
            border-radius: 10px;
            text-align: center;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
            animation: fadeIn 0.3s ease-out;
          }

          .spinner {
            border: 4px solid rgba(255, 255, 255, 0.3);
            border-top: 4px solid #00b4d8;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            margin: 0 auto;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
}

export default AddContent;
