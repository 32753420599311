import React, { useState, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import ChallengesSubmission from "./ChallengesSubmission";
import ChallengeReviewForm from "./ChallengeReviewForm";
import { auth } from "../firebase";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import StarIcons from "./StarIcons";

function Challenges({ challenges, type }) {
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [showResponses, setShowResponses] = useState(false);
  const [showSubmission, setShowSubmission] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState("All"); // Topic filter state

  let cont = type === "community" ? CommunityContext : SubjectsContext;
  const values = useContext(cont);

  const openModal = (index) => {
    setSelectedChallenge(filteredChallenges[index]);
    setShowResponses(false);
    setShowSubmission(false);
  };

  const closeModal = () => {
    setSelectedChallenge(null);
    setShowResponses(false);
    setShowSubmission(false);
  };

  const toggleViewResponses = () => {
    setShowResponses(true);
    setShowSubmission(false);
  };

  const toggleSendAnswer = () => {
    setShowSubmission(true);
    setShowResponses(false);
  };

  const { displayName } = auth.currentUser;

  // Filter challenges based on selected topic
  const filteredChallenges =
    selectedTopic === "All"
      ? challenges
      : challenges.filter((challenge) => challenge.topic === selectedTopic);

  // Get unique topics for the dropdown
  const uniqueTopics = ["All", ...new Set(challenges.map((ch) => ch.topic))];

  return (
    <div className="my-5">
      <h3 className="mx-1 my-5 fw-bolder text-primary">Questions</h3>

      {/* Topic Filter Dropdown */}
      <div style={{ maxWidth: "400px" }} className="mb-4">
        <label className="fw-bold">Select Topic:</label>
        <select
          className="form-select mt-2"
          value={selectedTopic}
          onChange={(e) => setSelectedTopic(e.target.value)}
        >
          {uniqueTopics.map((topic, index) => (
            <option key={index} value={topic}>
              {topic}
            </option>
          ))}
        </select>
      </div>

      <div>
        {filteredChallenges.length > 0 ? (
          filteredChallenges.map((challenge, index) => {
            const completedByCurrentUser = challenge.responses.some(
              (response) => response.displayName === displayName
            );
            return (
              <div
                key={index}
                className="quiz-card shadow-lg p-3 mb-3 bg-white rounded"
              >
                <small className="text-primary">
                  {index + 1}.{" "}
                  {completedByCurrentUser ? (
                    <span>
                      answered{" "}
                      <i className="fas fa-check-circle text-success m-1 ml-2"></i>
                    </span>
                  ) : (
                    <span>
                      not answered{" "}
                      <i className="far fa-circle text-secondary m-1 ml-2"></i>
                    </span>
                  )}
                </small>

                <div dangerouslySetInnerHTML={{ __html: challenge.description }}></div>
                <div>
                  <img style={{ maxWidth: "300px", maxHeight: "300px", objectFit: "cover" }} src={challenge.fileUrl1} alt="Question File" className="img-fluid" />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary text-light p-1 d-flex align-items-center"
                      onClick={() => {
                        openModal(index);
                        toggleViewResponses();
                      }}
                    >

                      answers {challenge.responses?.length}
                    </button>

                    <button
                      className="btn btn-success mx-1 text-light p-1 d-flex align-items-center"
                      onClick={() => {
                        openModal(index);
                        toggleSendAnswer();
                      }}
                    >
                      Send answer
                    </button>
                  </div>


                  {displayName === challenge?.name ? (
                    <>
                      <span className="btn btn-outline-danger" onClick={(e) => values.deleteChallengeFromSubject(e, challenge.subject_id, challenge.lesson_id)}>delete</span>
                    </>
                  ) : (<>
                    <small>
                      Sent by{" "}
                      <span className="text-primary">{challenge?.name}</span>
                    </small>
                  </>)}

                </div>
              </div>
            );
          })
        ) : (
          <p className="text-danger">No questions available for this topic.</p>
        )}
      </div>

      {/* Modal */}
      <Modal show={selectedChallenge !== null} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        {selectedChallenge && (
          <Modal.Body>
            <section style={{ maxWidth: "700px", maxHeight: "700px", overflow: "scroll" }}>
              <p>
                <strong>Question:</strong>{" "}
                <span
                  className="text-primary fw-bolder"
                  dangerouslySetInnerHTML={{
                    __html: selectedChallenge.description,
                  }}
                />
              </p>
              <div>
                <img src={selectedChallenge.fileUrl1} alt="Question File" className="img-fluid" />
              </div>

            </section>

            {showResponses && (
              <>
                <h5 className="fw-bolder text-dark mt-5">Answers {selectedChallenge.responses?.length} </h5>

                {selectedChallenge.responses?.length > 0 ? (
                  selectedChallenge.responses.map((response, responseIndex) => (
                    <div style={{ maxWidth: "700px", maxHeight: "700px", overflow: "scroll" }} key={responseIndex} className="quiz-card shadow-lg p-3 mb-5 bg-white rounded">
                      
                      <p className="fw-bolder text-secondary">
                       {responseIndex + 1}.average Score {" "}<span className="text-primary"> {response.reviews?.reduce((sum, review) => sum + review.score, 0) / response.reviews?.length} </span>
                      </p>
                     
                      <h6 className="fw-bolder text-success">
                        {response.displayName}
                      </h6>
                      <p
                        className="text-primary"
                        dangerouslySetInnerHTML={{
                          __html: response.summary,
                        }}
                      />
                      <div>
                        <div>
                          <img src={response.fileUrl} alt="Question File" className="img-fluid" />
                        </div>
                      </div>
                      <div>
                        <p className="text-warning fw-bolder">Reviews {response.reviews?.length}</p>
                        {response.reviews?.map((review, reviewIndex) => (
                          <div key={reviewIndex} className="mb-3">
                            <p className="text-success m-0">
                              <strong>{reviewIndex + 1}: </strong>{" "}
                              {review.displayName}
                            </p>
                            <StarIcons averageRating={review.score} />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: review.summary,
                              }}
                            />
                          </div>
                        ))}
                      </div>

                      <ChallengeReviewForm
                        subjectName={selectedChallenge?.subject_id}
                        challengeId={selectedChallenge?.lesson_id}
                        responseId={response?.responseId}
                        type={type}
                      />
                    </div>
                  ))
                ) : (
                  <p>No responses available.</p>
                )}
              </>
            )}

            {showSubmission && (
              <div className="shadow-lg p-3 bg-white rounded">
                <ChallengesSubmission
                  subjectName={selectedChallenge?.subject_id}
                  challengeId={selectedChallenge?.lesson_id}
                  type={type}
                />
              </div>
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Challenges;
