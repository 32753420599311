import React, { useState, useEffect, useRef, useContext } from "react";
import { auth, projectFirestore as firestore } from "../firebase";
import { Modal } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import { UsersContext } from "./Contexts/UsersContext";
import { FaTrashAlt } from "react-icons/fa"; // You can use this for the delete icon
import { motion, AnimatePresence } from "framer-motion";

const Quizzes = ({ data, subject, topic, students, grade, type }) => {
  const [user] = useAuthState(auth);
  const [submitted, setSubmitted] = useState(false);
  const [results, setResults] = useState([]);
  const [answeredQuestionsLog, setAnsweredQuestionsLog] = useState([]);
  const [filter, setFilter] = useState("unanswered");
  const allMethods = Array.from(new Set(data.flatMap((quiz) => quiz.methods)));
  const [selectedMethod, setSelectedMethod] = useState("All Methods");
  const [numberOfQuizzes, setNumberOfQuizzes] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const { users } = useContext(UsersContext);

  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const submittedRef = useRef(null);

  useEffect(() => {
    if (user) {
      const currentUser = users.find((u) => u.uid === user.uid);
      if (currentUser && currentUser.answeredQuestionsLog) {
        setAnsweredQuestionsLog(currentUser.answeredQuestionsLog);
      }
    }
  }, [user, users]);

  useEffect(() => {
    const filterData = () => {
      const filtered = data
        .filter((quiz) => {
          if (
            selectedMethod !== "All Methods" &&
            !quiz.methods.includes(selectedMethod)
          ) {
            return false;
          }
          if (filter === "unanswered") {
            return !answeredQuestionsLog.some((entry) =>
              entry.quizResults.some((r) => r.question === quiz.question)
            );
          }
          return true;
        })
        .slice(0, numberOfQuizzes);

      setFilteredData(filtered);
      setSelectedAnswers(new Array(filtered.length).fill(null));
      setCurrentQuizIndex(0);
    };

    filterData();
  }, [data, selectedMethod, filter, numberOfQuizzes, answeredQuestionsLog]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleAnswerSelection = (quizIndex, answerIndex) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[quizIndex] = answerIndex;
    setSelectedAnswers(newSelectedAnswers);
  };

  const handleNext = () => {
    if (currentQuizIndex < filteredData.length - 1) {
      setDirection(1);
      setCurrentQuizIndex(currentQuizIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuizIndex > 0) {
      setDirection(-1);
      setCurrentQuizIndex(currentQuizIndex - 1);
    }
  };

  const handleSubmit = async () => {
    const newResults = filteredData
      .map((quiz, quizIndex) => {
        const selectedAnswerIndex = selectedAnswers[quizIndex];
        if (selectedAnswerIndex !== null && selectedAnswerIndex !== undefined) {
          const isCorrect = quiz.answers[selectedAnswerIndex].is_correct;
          return {
            topic: selectedMethod,
            subject: quiz.subject,
            grade: quiz.grade,
            question: quiz.question,
            isCorrect,
            methods: quiz.methods,
            correctAnswer: quiz.answers.find((answer) => answer.is_correct)
              .answer_text,
          };
        } else {
          return null;
        }
      })
      .filter((result) => result !== null);

    if (newResults.length === 0) {
      return;
    }

    setResults(newResults);
    setSubmitted(true);

    try {
      const userDocRef = firestore
        .collection("usersCollection")
        .doc("usersDoc");
      const doc = await userDocRef.get();
      if (doc.exists) {
        const { users } = doc.data();
        const currentUser = users.find((u) => u.uid === user.uid);
        if (currentUser) {
          let answeredQuestionsLog = currentUser.answeredQuestionsLog || [];
          const unansweredResults = newResults.filter(
            (result) =>
              !answeredQuestionsLog.some((entry) =>
                entry.quizResults.some((r) => r.question === result.question)
              )
          );

          if (unansweredResults.length > 0) {
            const answeredQuestionsLogEntry = {
              quizResults: unansweredResults,
            };
            answeredQuestionsLog.push(answeredQuestionsLogEntry);
            await userDocRef.update({
              users: users.map((user) =>
                user.uid === currentUser.uid
                  ? { ...user, answeredQuestionsLog }
                  : user
              ),
            });
          }
        }
      }
    } catch (error) {
      console.error("Error updating answered questions log:", error);
    }

    if (submittedRef.current) {
      submittedRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const currentQuiz = filteredData[currentQuizIndex];

  const slideVariants = {
    initial: (direction) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    animate: {
      x: "0%",
      opacity: 1,
      transition: { duration: 0.4, ease: "easeOut" },
    },
    exit: (direction) => ({
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
      transition: { duration: 0.4, ease: "easeIn" },
    }),
  };

  const [direction, setDirection] = useState(0);

  return (
    <div className="my-5">
      {/* Filter Buttons */}
      <div className="filter-buttons w-100">
        <p
          className={`m-1 btn ${filter === "unanswered" ? "btn-outline-dark" : "btn-outline-secondary"}`}
          onClick={() => handleFilterChange("unanswered")}
          disabled={submitted}
        >
        New Questions
        </p>
        <p
          className={`m-1 mx-2 btn ${filter === "all" ? "btn-outline-dark" : "btn-outline-dark"}`}
          onClick={() => handleFilterChange("all")}
          disabled={submitted}
        >
         All Questions
        </p>
        <div className="d-flex my-4">
          {/* Topic selection */}
          <div style={{ maxWidth: "200px" }} className="method-filter">
            <label htmlFor="methodSelect" className="form-label">
              Select Question Topic:
            </label>

            <select
              id="methodSelect"
              value={selectedMethod}
              onChange={handleMethodChange}
              disabled={submitted}
              className="form-select"
            >
              <option value="All Methods">All Topics</option>

              {allMethods.map((method, index) => (
                <option key={index} value={method}>
                  <span dangerouslySetInnerHTML={{ __html: method }}></span>
                </option>
              ))}
            </select>
          </div>


          {/* Number of questions selection */}
          <div style={{ maxWidth: "200px" }} className="select-quizzes mx-2">
            <label htmlFor="numberOfQuizzes" className="form-label">
            Number of Questions:
            </label>

            <select
              id="numberOfQuizzes"
              value={numberOfQuizzes}
              onChange={(e) => setNumberOfQuizzes(parseInt(e.target.value))}
              disabled={submitted}
              className="form-select"
            >
              <option value={5}>5 questions</option>
              <option value={10}>10 questions</option>
              <option value={15}>15 questions</option>
            </select>
          </div>

        </div>
      </div>

      {/* Quiz Container with animation */}
      <AnimatePresence custom={direction} mode="wait">
        {filteredData.length > 0 && currentQuiz && (
          <motion.div
            key={currentQuizIndex}
            variants={slideVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={direction}
            className="quiz-card shadow-lg p-3 mb-5 bg-white rounded"
          >
            <div>
              <h5 className="text-secondary fw-bolder">{currentQuizIndex + 1} / {filteredData?.length}.</h5>
              {user.displayName === currentQuiz.displayName && (
                <FaTrashAlt
                  className="delete-icon"
                  onClick={(e) =>
                    values.deleteQuizFromSubject(
                      e,
                      currentQuiz.subject,
                      currentQuiz.quizId
                    )
                  }
                />
              )}
              <p className="text-primary fw-bolder">
                <span
                  dangerouslySetInnerHTML={{ __html: currentQuiz.question }}
                />
              </p>
            </div>
            <div className="d-flex flex-wrap">
              {currentQuiz.answers.map((answer, answerIndex) => (
                <button
                  key={answerIndex}
                  className={`btn btn-outline-primary m-2 ${selectedAnswers[currentQuizIndex] === answerIndex ? "active" : ""
                    }`}
                  onClick={() => handleAnswerSelection(currentQuizIndex, answerIndex)}
                  disabled={submitted}
                >
                  <span dangerouslySetInnerHTML={{ __html: answer.answer_text }} />
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Navigation Buttons */}
      <div className="d-flex justify-content-between my-3">
        <button
          className="btn btn-secondary"
          onClick={handleBack}
          disabled={currentQuizIndex === 0 || submitted}
        >
          <i className="bi bi-arrow-left"></i> Back
        </button>
         
        {currentQuizIndex === filteredData.length - 1 ? (
          <button className="btn btn-success" onClick={handleSubmit} disabled={submitted}>
            Submit your answers
          </button>
        ) : (
          <button className="btn btn-primary" onClick={handleNext} disabled={submitted}>
            Next <i className="bi bi-arrow-right"></i>
          </button>
        )}
      </div>

      {/* Modal for Results */}
      <Modal
        show={submitted}
        onHide={() => setSubmitted(false)}
        centered
        className="quiz-results-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {submitted && (
            <div ref={submittedRef} className="quiz-results-content">
              {results.length > 0 ? (
                <ul className="list-group">
                  {results.map((result, index) => (
                    <li
                      key={index}
                      className={`list-group-item ${result.isCorrect ? "list-group-item-success" : "list-group-item-danger"
                        }`}
                    >
                      <div className="fw-bold">
                        <span
                          dangerouslySetInnerHTML={{ __html: result.question }}
                        />
                      </div>
                      <p className="mb-1">
                        <strong>Status:</strong>{" "}
                        {result.isCorrect ? (
                          <span className="text-success">Correct ✅</span>
                        ) : (
                          <span className="text-danger">Incorrect ❌</span>
                        )}
                      </p>
                      {!result.isCorrect && (
                        <p>
                          <strong>Correct Answer:</strong>{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: result.correctAnswer,
                            }}
                            className="text-success"
                          />
                        </p>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-success text-center fw-bold">
                  All answers submitted!
                </p>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Quizzes;
