import React, { useRef, useState } from "react";
import { projectFirestore, auth, projectStorage as storage } from "../firebase";
import firebase from "firebase/compat/app";
import DisplayContent from "./DisplayContent";
import { v4 } from "uuid";
import CreateQuizQuestion from "./CreateQuizQuestion";
import { Table, Modal, Button } from "react-bootstrap";

function CreateContent({ lesson_id, createQuiz, path, lesson_info }) {

  const route = `${path}/lesson/l`;

  const dummy = useRef();
  const nameRef = useRef();
  const descriptionRef = useRef();
  const subjectsRef = projectFirestore.collection(route);

  const [file, setFile] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [create_quiz, setCreateQuiz] = useState(false)

  const sendSubject = async (e) => {

    e.preventDefault();

    setIsCreating(true);

    const { uid, displayName, photoURL } = auth.currentUser;

    const c_id = v4();

    const maxSizeInBytes = 500 * 1024 * 1024;

    if (file && file.size > maxSizeInBytes) {

      alert("file size is too large, maximum file size is 500 mb");

      setFile(null);

      document.getElementById("fileInput").value = "";

    } else {

      const subjectName = nameRef.current.value;

      const querySnapshot = await subjectsRef

        .where("name", "==", subjectName)
        .get();

      if (subjectName === "") {
        setIsCreating(false);
        alert("enter the content name to create");
        return;
      }
      if (!querySnapshot.empty) {

        setIsCreating(false);

        alert("name already exists , try another name");

        return;
      }

      const newSubject = {
        name: subjectName,
        description: descriptionRef.current.value,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        c_id,
        displayName,
        photoURL,
      };

      if (file) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        const downloadURL = await fileRef.getDownloadURL();
        newSubject.file = {
          name: file.name,
          url: downloadURL,
        };
      }

      await subjectsRef.add(newSubject);

      alert("content added successfully!");
    }
    setFile(null);
    document.getElementById("fileInput").value = "";
    setIsCreating(false);
    dummy.current.scrollIntoView({ behavior: "smooth" });
  };



  return (
    <div className="container xs-container-fluid mt-5 mb-5">

      <main className="subject-wrapper mt-5">
        <DisplayContent lesson_info={lesson_info} lesson_id={lesson_info.lesson_id} createQuiz={createQuiz} route={route} />
        <span ref={dummy}></span>
      </main>

    </div>
  );
}

export default CreateContent;
