import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DisplayLesson from "./DisplayLesson";
import SubjectsContext from "./Contexts/SubjectContext";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import ReviewForm from "./ReviewForm";

function Subject() {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const values = useContext(SubjectsContext);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (location?.state?.data) {
      setData(location.state.data);
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (data && user?.displayName) {
      // If user is not already in the subject, add them automatically
      if (!data?.students?.includes(user.displayName)) {
        values.addStudentToSubject(data.name, user.displayName);
      }
    }
  }, [data, user, values]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const route = `${data?.name}/lesson/l/lesson/l`;

  return (
    <div>


      <div className="container">
        <div className=" mt-5 mb-5">
          <h3
            className="text-primary py-2"
          >
            {data?.name}
          </h3>
        </div>
        <DisplayLesson subject={data?.name} route={route} />
      </div>

      <div className="container">
        <ReviewForm SubjectName={data?.name} subject={data} type={"subject"} />
      </div>
    </div>
  );
}

export default Subject;
