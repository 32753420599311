import React, { useState, useContext } from "react";
import SubjectsContext from "./Contexts/SubjectContext";
import CommunityContext from "./Contexts/CommunityContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

function CreateQuizQuestion({ lesson_id, subject, type }) {
  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const [loading, setLoading] = useState(false);
  const [numQuestions, setNumQuestions] = useState(5); // Set default to 5
  const [questions, setQuestions] = useState([]);
  const [methods, setMethods] = useState(""); // Single methods state

  const handleNumQuestionsChange = (event) => {
    const count = parseInt(event.target.value, 10);
    // Prevent selecting less than 5 questions
    if (count < 5) {
      alert("You must select at least 5 questions.");
      return;
    }
    setNumQuestions(count);

    const initialQuestions = Array.from({ length: count }, () => ({
      grade: 0,
      subject: subject,
      topic: lesson_id,
      question: "",
      methods: methods, // Use the shared methods value
      answers: [
        { answer_text: "", is_correct: false },
        { answer_text: "", is_correct: false },
        { answer_text: "", is_correct: false },
        { answer_text: "", is_correct: false },
      ],
    }));

    setQuestions(initialQuestions);
  };

  const handleMethodsChange = (value) => {
    setMethods(value);
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => ({ ...q, methods: value }))
    );
  };

  const handleQuestionChange = (index, name, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][name] = value;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, name, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers[aIndex][name] =
      name === "is_correct" ? value.target.checked : value;
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate questions
    if (!methods.trim()) {
      alert("Methods must be filled!");
      return;
    }

    for (const question of questions) {
      if (!question.question.trim()) {
        alert("All questions must be filled!");
        return;
      }

      if (question.answers.some((answer) => !answer.answer_text.trim())) {
        alert("All answer options must be filled!");
        return;
      }
    }

    setLoading(true);

    try {
      await Promise.all(
        questions.map((q) =>
          values.addQuizToSubject(e, subject, lesson_id, q)
        )
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"],
        [{ align: [] }],
      ],
    },
  };

  return (
    <div className="mt-2">
      {/* Loading Popup */}
    
      {loading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <div className="spinner"></div>
            <p>Creating Quiz, Please Wait...</p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div>
          <label><h4 className="fw-bolder ">Topic</h4></label>
          <ReactQuill
            value={methods}
            onChange={handleMethodsChange}
            placeholder="Type your topic here"
            className="react-quill"
          />
        </div>

        <div>
          <label className="fw-bolder" htmlFor="numQuestions">Number of Questions:</label>
          <select
            id="numQuestions"
            value={numQuestions}
            onChange={handleNumQuestionsChange}
            className="form-select"
          >
            <option value={5}>Select</option>
            {[5, 6, 7, 8, 9, 10].map((n) => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </select>
        </div>

        {questions.map((question, qIndex) => (
          <div key={qIndex} className="mt-4">
            <div>
              <label><h3 className="fw-bolder text-primary">Question {qIndex + 1}</h3></label>
              <ReactQuill
                value={question.question}
                onChange={(value) =>
                  handleQuestionChange(qIndex, "question", value)
                }
                placeholder="Type your question here"
                className="react-quill"
                modules={modules}
              />
            </div>

            <div>
              <p className="fw-bolder text-success mt-3"><span className="text-primary">Question {qIndex + 1}{" : "}</span>Answers</p>
              {question.answers.map((answer, aIndex) => (
                <div key={aIndex}>
                  <h5 className="fw-bolder text-success mt-3">Answer {aIndex + 1}</h5>
                  <ReactQuill
                    value={answer.answer_text}
                    onChange={(value) =>
                      handleAnswerChange(qIndex, aIndex, "answer_text", value)
                    }
                    placeholder={`Answer option ${aIndex + 1}`}
                    className="react-quill"
                    modules={modules}
                  />
                  <label>
                    <input
                      type="checkbox"
                      name="is_correct"
                      className="fw-bolder"
                      checked={answer.is_correct}
                      onChange={(e) =>
                        handleAnswerChange(qIndex, aIndex, "is_correct", e)
                      }
                    />
                    Mark as Correct
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="my-4">
          <button
            type="submit"
            className="btn px-3 py-2 btn-success"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit All Questions"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateQuizQuestion;
