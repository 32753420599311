import React, { useState, useEffect, useContext } from 'react';
import { UsersContext } from "./Contexts/UsersContext"
import { useAuth } from '../contexts/AuthContext'; // Adjust the path if necessary

const Users = () => {
  const { users: allUsers } = useContext(UsersContext);
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser, disableUser, enableUser, logout } = useAuth();
  const { uid } = currentUser;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  const handleToggleUserStatus = async (userId, isDisabled) => {
    try {
      if (isDisabled) {
        await enableUser(userId);
      } else {
        await disableUser(userId);
      }
      setUsers(users.map(user => user.id === userId ? { ...user, disabled: !isDisabled } : user));

      if (userId === uid && isDisabled) {
        await logout();
      }
    } catch (error) {
      console.error(`Error ${isDisabled ? 'enabling' : 'disabling'} user:`, error);
    }
  };

  const filteredUsers = users.filter(user =>
    (user.school && user.school.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (user.displayName && user.displayName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (user.code && user.code.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const sortedUsers = filteredUsers.sort((a, b) => {
    if (a.school && b.school) {
      if (a.school.toLowerCase() !== b.school.toLowerCase()) {
        return a.school.toLowerCase() < b.school.toLowerCase() ? -1 : 1;
      } else {
        return a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1;
      }
    } else if (a.school) {
      return 1;
    } else if (b.school) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <div className="container mt-5">
      <h5 className="mb-4 text-primary">User Accounts</h5>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by school, email, display name, or code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Display Name</th>
            <th>Email</th>
            <th>School</th>
            <th>Code</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.displayName}</td>
              <td>{user.email}</td>
              <td>{user.school}</td>
              <td>{user.code}</td>
              <td>{user.disabled ? "Disabled" : "Enabled"}</td>
              <td>
                <button
                  onClick={() => handleToggleUserStatus(user.id, user.disabled)}
                  className={`btn btn-${user.disabled ? 'success' : 'danger'}`}
                >
                  {user.disabled ? 'Enable' : 'Disable'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
