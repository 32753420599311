import React, { useRef, useState } from "react";
import {
  projectFirestore,
  auth,
  projectStorage as storage,
} from "../../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { FaWindows } from "react-icons/fa";

const SubjectsContext = React.createContext();

const SubjectsProvider = ({ children }) => {
  const navigate = useNavigate();
  const path = "subjects";
  const dummy = useRef();
  const nameRef = useRef();
  const reviewRef = useRef();
  const descriptionRef = useRef();
  const priceRef = useRef();

  const subjectsRef = projectFirestore.collection(path);
  const query = subjectsRef.orderBy("createdAt");

  const [subjects] = useCollectionData(query, { idField: "id" });

  const [formValue, setFormValue] = useState("");
  const [file, setFile] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const sendSubject = async (e) => {
    e.preventDefault();

    setIsCreating(true);

    const { uid, displayName, photoURL, bio, education, modules } =
      auth.currentUser;
    const sub_id = v4();

    const subjectName = nameRef.current.value;
    const is_published = false;
    const is_reviewed = false;
    const re_edit = false;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      alert("name already exists , try another name");
      setIsCreating(false);
      return;
    }

    const newSubject = {
      is_published,
      is_reviewed,
      re_edit,
      students: [],
      reviews: [],
      review: "",
      name: subjectName,
      description: descriptionRef.current.value,
      price: priceRef.current.value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      sub_id,
      learningOutcomes: [],
      courseStructure: [],
      messages: [],
      lessons: [],
      quizzes: [],
      challenges: [],
      displayName,
      instructor: {
        uid,
        displayName,
        photoURL,
        bio: bio || "", // Provide a default empty string if bio is undefined
        education: education || "", // Provide a default empty string if education is undefined
        modules: modules || "", // Provide a default empty string if modules is undefined they can teach
        is_instructor: true,
      },
      photoURL,
    };

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

      if (imageExtensions.includes(extension)) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        const downloadURL = await fileRef.getDownloadURL();
        newSubject.file = {
          name: file.name,
          url: downloadURL,
        };
      } else {
        setIsCreating(false);
        alert("incorrect file type , select an image file!");

        return;
      }
    }

    await subjectsRef.add(newSubject);
    alert("subject created successfully!");
    setFormValue("");
    setFile(null);
    setIsCreating(false);
    navigate("display-user-subjects");
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handlePublish = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        is_published: true,
      });
    }
  };

  const handleReEdit = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        re_edit: true,
        is_reviewed: false,
        is_published: false,
        review: reviewRef.current.value,
      });
    }
  };

  const handleReview = async (subject_name) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        is_reviewed: true,
      });
    }
  };

  const addReviewAndRating = async (subject_name, review, rating, user) => {
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Check if the user has already sent a review for this subject
      const hasUserReviewed = subjectData.reviews.some((r) => r.user === user);

      if (hasUserReviewed) {
        alert("You have already sent a review for this subject.");
        return;
      }

      await docRef.update({
        reviews: firebase.firestore.FieldValue.arrayUnion({
          review,
          rating,
          user,
        }),
      });

      alert("Review and rating added successfully!");
    }
  };

  const addStudentToSubject = async (subject_name, student) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subject_name)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      await docRef.update({
        students: firebase.firestore.FieldValue.arrayUnion(student),
      });
    }
   
  };
  
  const removeStudentFromSubject = async (e,subject_name, student) => {
    
    e.preventDefault();
    
    if (!window.confirm("Are you sure you want to leave this subject?")) {
      return;
    }
    
    const querySnapshot = await subjectsRef
      .where("name", "==", subject_name)
      .get();
  
    if (querySnapshot.empty) {
      alert("Subject not found.");
      return;
    }
  
    const docRef = querySnapshot.docs[0].ref;
    const subjectData = querySnapshot.docs[0].data();
  
    if (!subjectData.students.includes(student)) {
      alert("Student is not enrolled in this subject.");
      return;
    }
  
    try {
      await docRef.update({
        students: firebase.firestore.FieldValue.arrayRemove(student),
      });
  
      alert("Student successfully removed from subject!");
    } catch (error) {
      console.error("Error removing student:", error);
      alert("Failed to remove student. Please try again.");
    }
  };
  
  // Inside your SubjectsProvider component

  const deleteSubject = async (subject_name) => {
    const subjectName = subject_name;
  
    const querySnapshot = await subjectsRef.where("name", "==", subjectName).get();
  
    if (querySnapshot.empty) {
      alert("Subject not found.");
      return;
    }
  
    const docRef = querySnapshot.docs[0].ref;
    const subjectData = querySnapshot.docs[0].data();
  
    if (!window.confirm("Are you sure you want to delete this subject?")) {
      return;
    }
  
    try {
      const storageRef = storage.ref();
  
      // **Step 1: Delete subject cover image from Storage**
      if (subjectData.file && subjectData.file.url) {
        try {
          const fileRef = storage.refFromURL(subjectData.file.url);
          await fileRef.delete();
        } catch (error) {
          console.error("Error deleting subject cover image:", error);
        }
      }
  
      // **Step 2: Delete all lesson files from Storage**
      if (subjectData.lessons && subjectData.lessons.length > 0) {
        const deleteLessonFilesPromises = subjectData.lessons.map(async (lesson) => {
          const fileUrls = [
            lesson.fileUrl1,
            lesson.fileUrl2,
            lesson.fileUrl3,
            lesson.fileUrl4,
            lesson.fileUrl5,
          ].filter(url => url); // Remove null/undefined values
  
          return Promise.all(
            fileUrls.map(async (fileUrl) => {
              try {
                const fileRef = storage.refFromURL(fileUrl);
                await fileRef.delete();
              } catch (error) {
                console.error("Error deleting lesson file:", fileUrl, error);
              }
            })
          );
        });
  
        // Wait for all lesson file deletions to complete
        await Promise.all(deleteLessonFilesPromises);
      }
  
      // **Step 3: Delete subcollections manually**
      const subCollections = ["messages", "quizzes", "challenges", "reviews"];
      for (const collectionName of subCollections) {
        const collectionRef = docRef.collection(collectionName);
        const snapshot = await collectionRef.get();
        const deletePromises = snapshot.docs.map(doc => doc.ref.delete());
        await Promise.all(deletePromises);
      }
  
      // **Step 4: Delete the subject document**
      await docRef.delete();
  
      alert("Subject and all associated files deleted successfully!");
    } catch (error) {
      console.error("Error deleting subject:", error);
      alert(`Error deleting subject: ${error.message}`);
    }
  };
  
  
  const addContentToSubject = async (
    learningOutcomes,
    courseStructure,
    subjectId
  ) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      // Assuming learningOutcomes and courseStructure are arrays
      const updatedLearningOutcomes = [...learningOutcomes]; // Flatten the array if needed
      const updatedCourseStructure = [...courseStructure]; // Flatten the array if needed

      await docRef.update({
        learningOutcomes: firebase.firestore.FieldValue.arrayUnion(
          ...updatedLearningOutcomes
        ),
        courseStructure: firebase.firestore.FieldValue.arrayUnion(
          ...updatedCourseStructure
        ),
      });
    }
    alert("Learning outcomes  added successfully!");
  };

  const addLessonToSubject = async (lesson, subjectId, lessonAttributes) => {
    const querySnapshot = await subjectsRef.where("name", "==", subjectId).get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const storageRef = storage.ref();
  
      // Create file references only if files exist
      const files = [
        lessonAttributes.file1,
        lessonAttributes.file2,
        lessonAttributes.file3,
        lessonAttributes.file4,
        lessonAttributes.file5,
      ];
      const fileRefs = files.map((file) => (file ? storageRef.child(file.name) : null));
  
      // Function for retry logic on file upload
      const retryUpload = async (fileRef, file, retries = 3) => {
        try {
          if (file) {
            await fileRef.put(file);
          }
        } catch (error) {
          if (retries > 0) {
            console.log(`Retrying upload, attempts left: ${retries}`);
            await retryUpload(fileRef, file, retries - 1);
          } else {
            throw error;
          }
        }
      };
  
      try {
        // Upload all files in parallel
        await Promise.all(
          fileRefs.map((fileRef, index) =>
            fileRef && files[index] ? retryUpload(fileRef, files[index]) : null
          )
        );
  
        // Get all file download URLs in parallel
        const downloadUrls = await Promise.all(
          fileRefs.map((fileRef) => (fileRef ? fileRef.getDownloadURL() : null))
        );
  
        const { topic, description, summary } = lessonAttributes;
        const lesson_id = v4();
  
        // Create lessons array
        const lessonsWithAttributes = lesson.map((lessonName) => ({
          students: [],
          name: lessonName,
          topic,
          description,
          summary,
          fileUrl1: downloadUrls[0],
          fileUrl2: downloadUrls[1],
          fileUrl3: downloadUrls[2],
          fileUrl4: downloadUrls[3],
          fileUrl5: downloadUrls[4],
          file_name1: files[0]?.name || null,
          file_name2: files[1]?.name || null,
          file_name3: files[2]?.name || null,
          file_name4: files[3]?.name || null,
          file_name5: files[4]?.name || null,
          lesson_id,
          subject_id: subjectId,
        }));
  
        // Batch update Firestore
        const batch = firebase.firestore().batch();
        batch.update(docRef, {
          lessons: firebase.firestore.FieldValue.arrayUnion(...lessonsWithAttributes),
        });
        await batch.commit();
  
        console.log("Lesson successfully added to subject!");
      } catch (error) {
        console.error("Error uploading lesson file:", error);
        alert("Error uploading file: Image in Class Content should be 1MB or less");
      }
    } else {
      console.error("No matching subject found");
      alert("No matching subject found for the given subjectId.");
    }
  };
  


  const addStudentToLesson = async (e, subject_name, lesson_id, student) => {
    e.preventDefault();
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();

      // Find the lesson within the subject's lessons array based on its lesson_id
      const lessonIndex = subjectData.lessons.findIndex(
        (lesson) => lesson.lesson_id === lesson_id
      );

      if (lessonIndex === -1) {
        alert("not found.");
        return;
      }

      const lessonToUpdate = subjectData.lessons[lessonIndex];

      // Ensure that the student and lessonToUpdate are defined
      if (!lessonToUpdate || !student) {
        alert("Invalid student or lesson data.");
        return;
      }

      // Check if the student is already enrolled in the lesson
      if (lessonToUpdate.students.includes(student)) {
        
        return;
      }

      // Add the student to the lesson's students array
      lessonToUpdate.students.push(student);

      // Update the lesson within the subject's lessons array
      subjectData.lessons[lessonIndex] = lessonToUpdate;

      // Update the subject document with the modified lesson
      await docRef.update({
        lessons: subjectData.lessons,
      });

      
    } else {
      
    }
  };

  const addMessagesToSubject = async (newMessage, subjectId) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const timestamp = firebase.firestore.Timestamp.now();
      const id = v4();
      const sentMessage = {
        id,
        text: newMessage.text,
        name: "", // You might want to populate this with an actual value.
        displayName: newMessage.displayName,
        photoURL: newMessage.photoURL,
        messageId: v4(),
        timestamp,
        responses: [],
        fileName: newMessage.file ? newMessage.file.name || "" : "",
        fileUrl: newMessage.file ? newMessage.file.url || "" : "",
      };

      await docRef.update({
        messages: firebase.firestore.FieldValue.arrayUnion(sentMessage),
      });
     
    } else {
      console.log("No documents found for subjectId:");
    }
  };

  const deleteMessageFromSubject = async (e, subject_name, messageId) => {
    e.preventDefault();
    const subjectName = subject_name;
  
    const querySnapshot = await subjectsRef.where("name", "==", subjectName).get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the message within the subject's messages array based on its messageId
      const messageIndex = subjectData.messages.findIndex(
        (message) => message.messageId === messageId
      );
  
      if (messageIndex === -1) {
        alert("Message not found.");
        return;
      }
  
      const messageToDelete = subjectData.messages[messageIndex];
  
      // Check if the message has an associated file and delete it from Firebase Storage
      if (messageToDelete.fileUrl) {
        try {
          const fileRef = firebase.storage().refFromURL(messageToDelete.fileUrl);
          await fileRef.delete();
          console.log("File deleted from storage:", messageToDelete.fileUrl);
        } catch (error) {
          console.error("Error deleting file from storage:", error);
        }
      }
  
      // Remove the message from the messages array
      subjectData.messages.splice(messageIndex, 1);
  
      // Update the subject document with the modified messages array
      await docRef.update({
        messages: subjectData.messages,
      });
  
      
    } else {
      alert("Subject not found.");
    }
  };
  

  const deleteLesson = async (e, subject_name, lesson_id) => {
    e.preventDefault();
  
    const confirmDelete = window.confirm("Are you sure you want to delete this class?");
    if (!confirmDelete) return;
  
    const subjectName = subject_name;
    const querySnapshot = await subjectsRef.where("sub_id", "==", subjectName).get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the lesson within the subject's lessons array
      const lessonIndex = subjectData.lessons.findIndex(lesson => lesson.lesson_id === lesson_id);
      if (lessonIndex === -1) {
        alert("Lesson not found.");
        return;
      }
  
      // Extract the lesson details
      const lesson = subjectData.lessons[lessonIndex];
  
      try {
        const storageRef = storage.ref(); // Firebase Storage reference
  
        // List of file URLs in the lesson
        const fileUrls = [
          lesson.fileUrl1,
          lesson.fileUrl2,
          lesson.fileUrl3,
          lesson.fileUrl4,
          lesson.fileUrl5,
        ].filter(url => url); // Remove null/undefined values
  
        // Delete each file from Firebase Storage
        const deleteFilePromises = fileUrls.map(async (fileUrl) => {
          try {
            const fileRef = storage.refFromURL(fileUrl);
            await fileRef.delete();
          } catch (error) {
            console.error("Error deleting file:", fileUrl, error);
          }
        });
  
        // Wait for all file deletions to complete
        await Promise.all(deleteFilePromises);
  
        // Remove the lesson from the lessons array
        subjectData.lessons.splice(lessonIndex, 1);
  
        // Update Firestore with the modified lessons array
        await docRef.update({
          lessons: subjectData.lessons,
        });
  
        alert("Lesson and associated files deleted successfully!");
      } catch (error) {
        console.error("Error deleting lesson:", error);
        alert("Error deleting lesson.");
      }
    } else {
      alert("Subject not found.");
    }
  };
  

  const addQuizToSubject = async (e, subject_name, lesson_id, quizData) => {
    e.preventDefault();
    const { uid, displayName, photoURL, bio, education, modules } =
      auth.currentUser;
    const subjectName = subject_name;

    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const quizId = v4();

      const newQuiz = {
        grade: 0,
        subject: subjectName,
        topic: lesson_id,
        displayName,
        question: quizData.question,
        methods: quizData.methods,
        answers: quizData.answers,
        quizId,
      };

      await docRef.update({
        quizzes: firebase.firestore.FieldValue.arrayUnion(newQuiz),
      });

      alert("Quiz added successfully!");
    } else {
      alert("Subject not found.");
    }
  };

  const addChallengeToSubject = async (lesson, subjectId, lessonAttributes) => {
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectId)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const storageRef = storage.ref(); // Firebase Storage reference

      const fileRef1 = lessonAttributes.file1
        ? storageRef.child(lessonAttributes.file1.name)
        : null;

      try {
        // Upload the file to Firebase Storage
        if (fileRef1) {
          await fileRef1.put(lessonAttributes.file1);
        }

        // Get the download URL for the uploaded file
        const fileDownloadURL1 = fileRef1
          ? await fileRef1.getDownloadURL()
          : null;

        const { topic, description, summary } = lessonAttributes;
        const file_name1 = lessonAttributes.file1 ? lessonAttributes.file1.name : null;
        const lesson_id = v4();
        const { uid, displayName } = auth.currentUser;
        const timestamp = new Date().toISOString();  // Capture the current time

        // Add attributes to each lesson
        const lessonsWithAttributes = lesson.map((lessonName) => ({
          responses: [],
          name: displayName,
          topic,
          description,
          summary,
          fileUrl1: fileDownloadURL1,
          file_name1,
          lesson_id,
          subject_id: subjectId,
        }));

        // Retrieve current subject data
        const subjectData = querySnapshot.docs[0].data();

        // Ensure courseStructure exists
        if (!subjectData.courseStructure) {
          subjectData.courseStructure = [];
        }

        // Add challenge activity to courseStructure
        subjectData.courseStructure.push({
          displayName,
          time: timestamp,
         
          is_read: false  // Mark activity as unread
        });

        // Update Firestore with challenges and courseStructure
        await docRef.update({
          challenges: firebase.firestore.FieldValue.arrayUnion(...lessonsWithAttributes),
          courseStructure: subjectData.courseStructure,
        });

        alert("Question sent!");
      } catch (error) {
        alert("Error uploading lesson file:", error);
        console.log("Upload error:", error);
      }
    } else {
      alert("Subject not found.");
    }
};


const addResponseToChallenge = async (subjectName, challengeId, responseAttributes) => {
  const querySnapshot = await subjectsRef.where("name", "==", subjectName).get();

  if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const storageRef = storage.ref(); // Firebase Storage reference

      const fileRef = responseAttributes.file ? storageRef.child(responseAttributes.file.name) : null;

      try {
          // Upload the file to Firebase Storage
          if (fileRef) {
              await fileRef.put(responseAttributes.file);
          }

          // Get the download URL for the uploaded file
          const fileDownloadURL = fileRef ? await fileRef.getDownloadURL() : null;

          const { summary } = responseAttributes;
          const fileName = responseAttributes.file ? responseAttributes.file.name : null;
          const responseId = v4();
          const { uid, displayName } = auth.currentUser;
          const timestamp = new Date().toISOString();  // Capture the current time

          const newResponse = {
              displayName,
              reviews: [],
              summary,
              responseId,
              uid, // Store user ID for checking duplicate responses
              fileUrl: fileDownloadURL,
              filename: fileName,
          };

          const subjectData = querySnapshot.docs[0].data();
          const challengeIndex = subjectData.challenges.findIndex(
              (challenge) => challenge.lesson_id === challengeId
          );

          if (challengeIndex !== -1) {
              const challenge = subjectData.challenges[challengeIndex];

              // Ensure responses array exists
              if (!challenge.responses) {
                  challenge.responses = [];
              }

              // Check if user has already responded
              const hasResponded = challenge.responses.some(response => response.uid === uid);
              if (hasResponded) {
                  alert("You have already submitted an answer.");
                  return;
              }

              // Add new response
              challenge.responses.push(newResponse);

              // Log user activity in courseStructure with is_read attribute
              if (!subjectData.courseStructure) {
                  subjectData.courseStructure = [];
              }

              subjectData.courseStructure.push({
                  displayName,
                  time: timestamp,
                  activity: `${displayName} answered a question in "${subjectName}"`,
                  is_read: false  // Mark activity as unread
              });

              // Update Firestore with new challenges and courseStructure
              await docRef.update({
                  challenges: subjectData.challenges,
                  courseStructure: subjectData.courseStructure,
              });

              alert("Answer sent successfully!");
          } else {
              alert("Question not found.");
          }
      } catch (error) {
          alert("Error uploading response file:", error);
          console.log("Upload error:", error);
      }
  } else {
      alert("Subject not found.");
  }
};


  

  const removeResponseFromChallenge = async (e,subjectName, challengeId, responseId) => {
    e.preventDefault();
     if(window.confirm("are you sure?")){
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the challenge within the subject's challenges array based on its challengeId
      const challengeIndex = subjectData.challenges.findIndex(
        (challenge) => challenge.lesson_id === challengeId
      );
  
      if (challengeIndex === -1) {
        alert(" not found.");
        return;
      }
  
      const challenge = subjectData.challenges[challengeIndex];
  
      // Find the response within the challenge's responses array based on its responseId
      const responseIndex = challenge.responses.findIndex(
        (response) => response.responseId === responseId
      );
  
      if (responseIndex === -1) {
        alert("Response not found.");
        return;
      }
  
      // Remove the response from the responses array
      challenge.responses.splice(responseIndex, 1);
  
      // Update the subject document with the modified challenges array
      await docRef.update({
        challenges: subjectData.challenges,
      });
  
      alert("deleted!");
    } else {
      alert("Subject not found.");
    }
  }
  };
  

  const addReviewToResponse = async (subjectName, challengeId, responseId, score, summary) => {
    const querySnapshot = await subjectsRef.where("name", "==", subjectName).get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
  
      const { uid, displayName } = auth.currentUser;
      const timestamp = new Date().toISOString();  // Capture the current time

      const newReview = {
        displayName,
        uid,  // Store user ID for checking duplicate reviews
        score,
        summary,
      };
  
      const subjectData = querySnapshot.docs[0].data();
      const challengeIndex = subjectData.challenges.findIndex(
        (challenge) => challenge.lesson_id === challengeId
      );
  
      if (challengeIndex !== -1) {
        const challenge = subjectData.challenges[challengeIndex];
        const responseIndex = challenge.responses.findIndex(
          (response) => response.responseId === responseId
        );
  
        if (responseIndex !== -1) {
          const response = challenge.responses[responseIndex];
  
          // Ensure reviews array exists
          if (!response.reviews) {
            response.reviews = [];
          }
  
          // Check if user has already reviewed
          const hasReviewed = response.reviews.some((review) => review.uid === uid);
          if (hasReviewed) {
            alert("You have already submitted a review for this response.");
            return;
          }
  
          // Add new review
          response.reviews.push(newReview);
  
          // Log review activity in courseStructure
          if (!subjectData.courseStructure) {
            subjectData.courseStructure = [];
          }

          subjectData.courseStructure.push({
            displayName,
            time: timestamp,
            activity: `${displayName} reviewed ${response.displayName}'s answer in "${subjectName}"`,
            is_read: false  // New attribute to track unread activity
          });
  
          // Update the document with new reviews and courseStructure
          await docRef.update({
            challenges: subjectData.challenges,
            courseStructure: subjectData.courseStructure,
          });
  
          alert("Review added successfully!");
        } else {
          alert("Response not found.");
        }
      } else {
        alert("Question not found.");
      }
    } else {
      alert("Subject not found.");
    }
};

  

  const addResponseToMessage = async (subjectName, messageId, response) => {
    console.log(
      "subject name",
      subjectName,
      "message id",
      messageId,
      "response",
      response
    );
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;

      const subjectData = querySnapshot.docs[0].data();
      const messageIndex = subjectData.messages.findIndex(
        (message) => message.id === messageId
      );

      if (messageIndex !== -1) {
        // Ensure responses array exists for the message
        if (!subjectData.messages[messageIndex].responses) {
          subjectData.messages[messageIndex].responses = [];
        }

        // Push the new response to the message's responses array
        subjectData.messages[messageIndex].responses.push(response);

        // Update the subject document with the modified messages array
        await docRef.update({
          messages: subjectData.messages,
        });
        alert("Sent");
      
      } else {
        alert("Message not found.");
      }
    } else {
      alert("Subject not found.");
    }
  };

  const deleteResponseFromMessage = async (e, subjectName, messageId, responseId) => {
    e.preventDefault();
  
    console.log("subject name", subjectName, "message id", messageId, "response", responseId);
  
    const querySnapshot = await subjectsRef.where("name", "==", subjectName).get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the message within the subject's messages array based on its messageId
      const messageIndex = subjectData.messages.findIndex((message) => message.id === messageId);
  
      if (messageIndex !== -1) {
        const message = subjectData.messages[messageIndex];
  
        // Find the response within the message's responses array based on its responseId
        const responseIndex = message.responses.findIndex((response) => response.id === responseId);
  
        if (responseIndex !== -1) {
          const responseToDelete = message.responses[responseIndex];
  
          // Check if the response has an associated file and delete it from Firebase Storage
          if (responseToDelete.fileUrl) {
            try {
              const fileRef = firebase.storage().refFromURL(responseToDelete.fileUrl);
              await fileRef.delete();
              console.log("File deleted from storage:", responseToDelete.fileUrl);
            } catch (error) {
              console.error("Error deleting file from storage:", error);
            }
          }
  
          // Remove the response from the responses array
          message.responses.splice(responseIndex, 1);
  
          // Update the subject document with the modified messages array
          await docRef.update({
            messages: subjectData.messages,
          });
  
          alert("Deleted");
        } else {
          alert("Response not found.");
        }
      } else {
        alert("Message not found.");
      }
    } else {
      alert("Subject not found.");
    }
  };
  
   
  const deleteQuizFromSubject = async (e, subject_name, quiz_id) => {
    e.preventDefault();

    if(window.confirm("are you sure?")){
    const subjectName = subject_name;

  
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the quiz within the subject's quizzes array based on its quiz_id
      const quizIndex = subjectData.quizzes.findIndex(
        (quiz) => quiz.quizId === quiz_id
      );
  
      if (quizIndex === -1) {
        alert("Quiz not found.");
        return;
      }
  
      // Remove the quiz from the quizzes array
      subjectData.quizzes.splice(quizIndex, 1);
  
      // Update the subject document with the modified quizzes array
      await docRef.update({
        quizzes: subjectData.quizzes,
      });
  
      alert("Quiz deleted successfully!");
    } else {
      alert("Subject not found.");
    }
  }
  };
  
  const deleteChallengeFromSubject = async (e, subject_name, challenge_id) => {
    e.preventDefault();
    if(window.confirm("are you sure?")){
    const subjectName = subject_name;
  
    const querySnapshot = await subjectsRef
      .where("name", "==", subjectName)
      .get();
  
    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subjectData = querySnapshot.docs[0].data();
  
      // Find the challenge within the subject's challenges array based on its challenge_id
      const challengeIndex = subjectData.challenges.findIndex(
        (challenge) => challenge.lesson_id === challenge_id
      );
  
      if (challengeIndex === -1) {
        alert("Challenge not found.");
        return;
      }
  
      // Remove the challenge from the challenges array
      subjectData.challenges.splice(challengeIndex, 1);
  
      // Update the subject document with the modified challenges array
      await docRef.update({
        challenges: subjectData.challenges,
      });
  
      alert("deleted successfully!");
    } else {
      alert("Subject not found.");
    }
  }
  };
  
  

  const values = {
    handleFileChange,
    sendSubject,
    path,
    dummy,
    nameRef,
    reviewRef,
    descriptionRef,
    subjectsRef,
    priceRef,
    query,
    subjects,
    setFormValue,
    setFile,
    sendSubject,
    formValue,
    handlePublish,
    handleReview,
    addReviewAndRating,
    handleReEdit,
    isCreating,
    addStudentToSubject,
    removeStudentFromSubject,
    addContentToSubject,
    addLessonToSubject,
    addStudentToLesson,
    addMessagesToSubject,
    deleteMessageFromSubject,
    deleteSubject,
    deleteLesson,
    addQuizToSubject,
    addChallengeToSubject,
    addResponseToChallenge,
    addReviewToResponse,
    addResponseToMessage,
    removeResponseFromChallenge,
    deleteResponseFromMessage,
    deleteQuizFromSubject,
    deleteChallengeFromSubject,
  };

  return (
    <SubjectsContext.Provider value={values}>
      {children}
    </SubjectsContext.Provider>
  );
};

export { SubjectsProvider };

export default SubjectsContext;
