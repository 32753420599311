import React, { useState, useContext,useEffect } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";
import image1 from "./images/create-community1.svg"
import subject_img from "./subject.svg"
import TeacherPayment from "../Payment/TeacherPayment"
import { useIntersectionObserver } from './useIntersectionObserver';

function DisplayUserSubjects() {
  const values = useContext(SubjectsContext);
  const [user] = useAuthState(auth);
  const [dropdownOpen, setDropdownOpen] = useState({}); // Track dropdown state for each subject
  const [showPaymentPopup, setShowPaymentPopup] = useState(false); // State for popup visibility
  
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const toggleDropdown = (subjectId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [subjectId]: !prevState[subjectId],
    }));
  };

  const userSubjects =
    values.subjects &&
    values.subjects.filter((subject) => subject.uid === user.uid);

  const togglePaymentPopup = () => {
    setShowPaymentPopup(!showPaymentPopup);
  };
  
  const setVisible = (element) => {
    element.classList.add('visible');
  };

  useIntersectionObserver(setVisible);
  return (
    <>
      <div style={{ width: '90%', margin: 'auto' }} className="mt-5 mb-5">

          {userSubjects && userSubjects.length > 0 ? (
            <>
               <p className="fw-bolder text-success">My Subjects</p>
            </>
          ) : (
            <p className="fw-bolder text-danger">You have not created a Subject yet</p>
          )}

        <section className="py-5">
          <div className="mt-5">
            <div className="subjects">
              {userSubjects &&
                userSubjects.map((subject, index) => (
                  <div className=" mb-5" key={index}>
                    {subject.is_published && !subject.is_reviewed ? (
                      <p className="my-2">waiting for review</p>
                    ) : subject.is_reviewed ? (
                      <div className="d-flex">
                      <p className="my-2">published</p>{" "}
                       <button
                          onClick={() => values.deleteSubject(subject.name)}
                          className="btn btn-outline-dark mx-3"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                      
                    ) : (
                      <div className="mb-2">
                        <div className="my-2">{subject.review && <p className="text-info">"{subject.review}"</p>}</div>
                        {/* Payment Popup */}
                        
                        <button
                          className="btn btn-success text-light"
                          onClick={() => values.handlePublish(subject.name)} // Toggle popup visibility
                        >
                          upload subject
                        </button>
                        {" "}
                        <button
                          onClick={() => values.deleteSubject(subject.name)}
                          className="btn btn-outline-dark mx-3"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    )}

                    <div>
                      <Link
                        to={`${subject.name}`}
                        style={{ textDecoration: "none" }}
                        className=" shadow-lg p-1 mb-5 bg-white rounded"
                      >
                        <div>
                          
                          <img
                            className="subject-img"
                            src={
                              subject.file
                                ? subject.file.url
                                : subject_img
                            }
                            alt="..."
                          />
                        
                          <div className=" ">
                            <div>
                              <p className="fw-bolder">{subject.name}</p>
                              <p className="text-secondary">{subject.description}</p>
                              
                              <p className="btn btn-secondary">Open Subject</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                      
                    </div>
                  </div>

                ))}
            </div>
          </div>
        </section>
      </div>

    </>
  );
}

export default DisplayUserSubjects;
