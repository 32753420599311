import React, { useState, useContext, useEffect, useRef } from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactPlayer from "react-player";
import SubjectsContext from "./Contexts/SubjectContext";

function DisplayContent({ lesson_info }) {
  const values = useContext(SubjectsContext);
  const [user] = useAuthState(auth);
  const [selectedContent, setSelectedContent] = useState("lesson"); // Default to lesson content
  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToContent = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleContentSelection = (content) => {
    setSelectedContent(content);
    scrollToContent();
  };

  const fileTypes = {
    image: ["jpg", "jpeg", "png", "gif"],
    audio: ["mp3", "wav", "ogg"],
    video: ["mp4", "avi", "mkv", "mov"],
    document: ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"],
  };

  const getFileType = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    if (fileTypes.image.includes(extension)) return "Image";
    if (fileTypes.audio.includes(extension)) return "Audio";
    if (fileTypes.video.includes(extension)) return "Video";
    if (fileTypes.document.includes(extension)) return "Document";
    return "File";
  };

  const renderFileContent = () => {
    if (selectedContent === "lesson") {
      return (
        <div className="lesson-content">
          <h6>📖 Class Lesson</h6>
          <div dangerouslySetInnerHTML={{ __html: lesson_info.topic }}></div>
          <div className="text-primary" dangerouslySetInnerHTML={{ __html: lesson_info.description }}></div>
          <div dangerouslySetInnerHTML={{ __html: lesson_info.summary }}></div>
        </div>
      );
    }

    if (!selectedContent) return null;
    const fileType = getFileType(selectedContent.fileName);

    switch (fileType) {
      case "Audio":
        return <audio src={selectedContent.fileUrl} controls />;
      case "Video":
        return <ReactPlayer url={selectedContent.fileUrl} width="100%" height="100%" controls />;
      case "Image":
        return <img src={selectedContent.fileUrl} alt="Lesson Content" className="img-fluid" />;
      case "Document":
        return <a href={selectedContent.fileUrl} target="_blank" rel="noopener noreferrer">View Document</a>;
      default:
        return <p>No content available.</p>;
    }
  };

  return (
    <div className="display-content-container container my-5 shadow-lg p-3  bg-white rounded">
      {/* Sidebar as a Column Layout */}
      <div className="sidebar-column my-2 shadow-lg p-3 mb-5 bg-white rounded">
        <div 
          className={`lesson-column ${selectedContent === "lesson" ? "active" : ""}`}
          onClick={() => handleContentSelection("lesson")}
        >
          <h6 className="fw-bolder">📘 Class Lesson</h6>
        </div>

        {[...Array(5)].map((_, index) => {
          const fileName = lesson_info[`file_name${index + 1}`];
          const fileUrl = lesson_info[`fileUrl${index + 1}`];

          return fileName ? (
            <div 
              key={index} 
              className={`file-column ${selectedContent?.fileName === fileName ? "active" : ""}`}
              onClick={() => handleContentSelection({ fileName, fileUrl })}
            >
              {fileName.substring(0, 20)}
            </div>
          ) : null;
        })}

        <button className="mark-complete" onClick={(e) => values.addStudentToLesson(e, lesson_info.subject_id, lesson_info.lesson_id, user.displayName)}>
          ✅ Mark Completed
        </button>
      </div>

      {/* Main Content Display (Scrolls into View when Clicked) */}
      {selectedContent !== null && (
        <div ref={contentRef} className="main-content-popup my-2  shadow-lg p-3  bg-white rounded">
          {renderFileContent()}
        </div>
      )}
    </div>
  );
}

export default DisplayContent;
