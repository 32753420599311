// UsersContext.js
import React, { createContext, useState, useEffect } from "react";
import { projectFirestore as firestore } from "../../firebase";

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetch users from usersCollection
        const usersDocSnapshot = await firestore
          .collection("usersCollection")
          .doc("usersDoc")
          .get();

        // Retrieve users data from the document
        const usersData = usersDocSnapshot.exists
          ? usersDocSnapshot.data().users
          : [];

        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error.message);
      }
    };

    fetchUsers();
  }, []);

  return (
    <UsersContext.Provider value={{ users }}>
      {children}
    </UsersContext.Provider>
  );
};

export { UsersContext, UsersProvider };
