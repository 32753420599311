import React, { useRef, useState, useContext, useEffect } from "react";
import { projectFirestore, auth, projectStorage as storage } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import ReactPlayer from "react-player";
import { v4 } from "uuid";
import SubjectsContext from "./Contexts/SubjectContext";
import MessageRepliesPopup from "./MessageRepliesPopup";
import CommunityContext from "./Contexts/CommunityContext";
import moment from "moment";

function Message({ path, type }) {
  return (
    <div className="chat mt-5 mb-5">
      <section>
        <ChatRoom path={path} type={type} />
      </section>
    </div>
  );
}

function ChatRoom({ path, type }) {
  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const subjectMessages = values.subjects?.filter(
    (subject) => subject.name == path
  );

  const subject_messages = subjectMessages[0]?.messages;

  const dummy = useRef();

  const messagesRef = projectFirestore.collection(`${path}`);

  const query = messagesRef.orderBy("createdAt");

  const [messages] = useCollectionData(query, { idField: "id" });

  const [formValue, setFormValue] = useState("");
  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const sendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    const maxSizeInBytes = 20 * 1024 * 1024;
    const { uid, displayName, photoURL } = auth.currentUser;

    if (file && file.size > maxSizeInBytes) {
      alert("file is too large, maximum file size is 20mb");
      document.getElementById("fileInput").value = "";
    } else {
      const newMessage = {
        id: v4(),
        text: formValue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        displayName,
        photoURL,
      };

      if (file) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(file.name);
        await fileRef.put(file);
        const downloadURL = await fileRef.getDownloadURL();

        newMessage.file = {
          name: file.name,
          url: downloadURL,
        };
      }

      if (selectedMessage && formValue !== "") {
        const replyMessage = {
          id: v4(),
          text: formValue,
          uid,
          displayName,
          subjectName: path,
          messageId: selectedMessage.id,
          photoURL,
        };
        if (file) {
          const storageRef = storage.ref();
          const fileRef = storageRef.child(file.name);
          await fileRef.put(file);
          const downloadURL = await fileRef.getDownloadURL();

          replyMessage.file = {
            name: file.name,
            url: downloadURL,
          };
        }

        // Add the reply message to the response array of the selected message
        values.addResponseToMessage(path, selectedMessage.id, replyMessage);

        setFormValue("");
        setFile(null);
        document.getElementById("fileInput").value = "";
        // dummy.current.scrollIntoView({ behavior: "smooth" });

        // Clear the selected message after sending the reply.
        setSelectedMessage(null);
        setLoading(false);
        return;

      }

      await values.addMessagesToSubject(newMessage, path);
    }

    setFormValue("");
    setFile(null);
    document.getElementById("fileInput").value = "";
    //dummy.current.scrollIntoView({ behavior: "smooth" });

    // Clear the selected message after sending the reply.
    setSelectedMessage(null);
    setLoading(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleReply = (message) => {
    //setFormValue(message.text);
    setSelectedMessage(message);
  };

  const handleCancelReply = (e) => {
    e.preventDefault();
    setSelectedMessage(null);
    setFormValue(e.target.value);
    setFile(null);
    document.getElementById("fileInput").value = "";
  };

  const [showPopup, setShowPopup] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleRepliesClick = (message) => {
    setSelectedMessage(message);
    setShowPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedMessage(null);
  };

  return (
    <>
      <div style={{ width: "100%", maxWidth: "1000px",maxHeight:"800px",overflowY:"scroll" }} className="messagesa quiz-card shadow-lg  bg-white rounded p-1 mb-4">
        {subject_messages &&
          subject_messages.map((msg) => (
            <>
              <ChatMessage
                type={type} // Pass the 'type' prop
                path={path} // Pass the 'path' prop
                key={msg.id}
                message={msg}
                selectedMessage={selectedMessage}
                setSelectedMessage={setSelectedMessage}
                onReply={handleReply}
                onRepliesClick={handleRepliesClick}
              />
            </>
          ))}
      </div>

      {showPopup && selectedMessage && (
        <MessageRepliesPopup
          selectedMessage={selectedMessage}
          onClose={handleClosePopup}
        />
      )}
      <form onSubmit={sendMessage} className="p-3 bg-light rounded shadow-sm">
        {selectedMessage && (
          <div className="alert alert-info d-flex justify-content-between align-items-center">
            <span>Replying to: {selectedMessage.text}</span>
            <button className="btn-close" onClick={handleCancelReply}></button>
          </div>
        )}

        <div className="d-flex flex-column flex-md-row align-items-start gap-2">
          <input
            type="text"
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Type your message..."
            className="form-control flex-grow-1"
          />

          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            className="form-control-file"
          />

          <button
            className="btn btn-success"
            type="submit"
            disabled={!formValue || loading}
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </div>
      </form>

    </>
  );
}


function ChatMessage(props) {
  const [user] = useAuthState(auth);
  const {
    text,
    timestamp,
    messageId,
    displayName,
    photoURL,
    fileName,
    fileUrl,
    responses = [],
  } = props.message;
  const { type, path } = props;

  let cont = type === "community" ? CommunityContext : SubjectsContext;
  const values = useContext(cont);

  const [showReplies, setShowReplies] = useState(false);

  const getFileType = (fileUrl) => {
    if (!fileUrl) return null;
    const extension = fileUrl.split(".").pop().toLowerCase();
    const types = {
      Image: ["jpg", "jpeg", "png", "gif"],
      Audio: ["mp3", "wav", "ogg"],
      Video: ["mp4", "avi", "mkv", "mov"],
      Document: ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"],
    };
    for (let type in types) {
      if (types[type].includes(extension)) return type;
    }
    return "File";
  };

  const handleReplyClick = () => {
    if (props.onReply) props.onReply(props.message);
  };

  const timeAgo = moment(timestamp.toDate()).fromNow();
  const formattedTimestamp = moment(timestamp.toDate()).format("LLL");

  return (
    <div className="chat-message my-2">
      {/* Chat Bubble */}
      <div className={`message-bubble p-3 rounded shadow-sm ${user.displayName === displayName ? "user-message" : "other-message"}`}>
        {/* User Info */}
        <div className="d-flex align-items-center mb-1">
          <i className="fa fa-user chat-avatar me-2"></i>
          <div>
            <h6 className="text-primary mb-0">{displayName}</h6>
            <small className="text-muted" title={formattedTimestamp}>
              {timeAgo}
            </small>
          </div>
        </div>

        {/* Message Text */}
        <p className="mb-1" dangerouslySetInnerHTML={{ __html: text }} />

        {/* File Attachments */}
        {fileUrl && (
          <div className="message-file mt-2">
          {getFileType(fileName) === "Audio" && (
            <>
              <ReactPlayer url={fileUrl} controls width="100%" height="50px" />
              <a href={fileUrl} download className="btn btn-outline-secondary btn-sm">
                <i className="fa fa-download"></i> {/* You can use a FontAwesome download icon */}
              </a>
            </>
          )}
          {getFileType(fileName) === "Video" && (
            <>
              <ReactPlayer url={fileUrl} controls width="100%" height="auto" />
              <a href={fileUrl} download className="btn btn-outline-secondary btn-sm">
                <i className="fa fa-download"></i> {/* FontAwesome download icon */}
              </a>
            </>
          )}
          {getFileType(fileName) === "Image" && (
            <>
              <img src={fileUrl} alt="Attachment" className="img-fluid rounded" />
              <a href={fileUrl} download className="btn btn-outline-secondary btn-sm">
                <i className="fa fa-download"></i> {/* FontAwesome download icon */}
              </a>
            </>
          )}
          {getFileType(fileName) === "Document" && (
            <>
              <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary btn-sm">
                {fileName}
              </a>
              <a href={fileUrl} download className="btn btn-outline-secondary btn-sm">
                <i className="fa fa-download"></i> {/* FontAwesome download icon */}
              </a>
            </>
          )}
        </div>
        
        )}

        {/* Actions */}
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span className="text-primary reply-btn" onClick={handleReplyClick}>
            Reply
          </span>
          {user.displayName === displayName && (
            <span className="text-danger delete-btn" onClick={(e) => values.deleteMessageFromSubject(e, path, messageId)}>
              Delete
            </span>
          )}
        </div>

        {/* Replies Dropdown */}
        {responses.length > 0 && (
          <div className="replies-section mt-2">
            <p className="text-success replies-toggle" onClick={() => setShowReplies(!showReplies)}>
              {showReplies ? `Hide Replies (${responses.length})` : `Show Replies (${responses.length})`}
            </p>

            {showReplies && (
              <div className="replies-list bg-light p-2 rounded">
                {responses.map((response) => (
                  <div key={response.id} className="reply-message p-2">
                    <strong className="text-success">{response.displayName}</strong>:{" "}
                    <span dangerouslySetInnerHTML={{ __html: response.text }} />
                    
                    {/* Display File Attachments in Replies */}
                    {response.file && (
                      <div className="mt-1">
                        {getFileType(response.file.name) === "Image" && (
                          <img src={response.file.url} alt="Reply Attachment" className="img-fluid rounded" />
                        )}
                        {getFileType(response.file.name) === "Document" && (
                          <a href={response.file.url} target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary btn-sm">
                            {response.file.name}
                          </a>
                        )}
                      </div>
                    )}

                    {/* Reply Delete Button */}
                    <br/>
                    {response.displayName === user.displayName && (
                      <small
                        className="text-danger ms-2 delete-reply-btn"
                        onClick={(e) => values.deleteResponseFromMessage(e, response.subjectName, response.messageId, response.id)}
                      >
                        Delete
                      </small>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}


export default Message;
