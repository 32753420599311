import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get the instructor's UID from the URL
import SubjectsContext from "./Contexts/SubjectContext";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";
import StarIcons from "./StarIcons";
import { useIntersectionObserver } from "./useIntersectionObserver";
import { UsersContext } from "./Contexts/UsersContext";

const Instructor = () => {
  const { subjects } = useContext(SubjectsContext);
  // const { instructorUid } = useParams(); // Get the instructor's UID from the URL parameter
  const { users } = useContext(UsersContext);
  const location = useLocation();

  const { data } = location.state;
  const [displayName, setDisplayName] = useState("");
  const [userBio, setUserBio] = useState("");
  const [userEducation, setUserEducation] = useState("");
  const [userModules, setUserModules] = useState([]); // Initialize as an empty array
  const [userPhoto, setUserPhoto] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  useEffect(() => {
    async function getUserData() {
      try {
        const currentUser = firebase.auth().currentUser;
        setDisplayName(currentUser.displayName);

        const userDoc = users?.filter((user) => user.uid === data.uid)[0];

        if (userDoc) {
          const userData = userDoc;
          setUserBio(userData.bio);
          setUserEducation(userData.education);
          setUserModules(userData.modules || []); // Ensure this is an array
          setUserPhoto(userData.photoURL);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
      setLoading(false);
    }

    getUserData();
  }, [users, data.uid]);

  const instructorSubjects = subjects?.filter(
    (subject) => subject.instructor.uid === data?.uid //instructorUid
  );

  const instructor =
    instructorSubjects?.length > 0 ? instructorSubjects[0]?.instructor : null;

  function calculateAverageRating(reviews) {
    if (reviews.length === 0) {
      return 0;
    }

    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length;
  }

  const publishedAndReviewedSubjects = instructorSubjects?.filter(
    (subject) => subject.is_published && subject.is_reviewed
  );

  const styleMb = {
    marginBottom: "200px",
  };

  const setVisible = (element) => {
    element.classList.add("visible");
  };

  useIntersectionObserver(setVisible);
  return (
    <div style={{ width: "90%", margin: "auto" }} className=" my-5">
      <h5 className="text-secondary">Teacher</h5>
      {instructor ? (
        <div className="my-4">
          <div>
            <div className="profile-info-grid ">
              <img
                style={{ width: "180px", height: "200px" }}
                src={userPhoto}
                alt="...what?"
              />
              <div className="mx-2">
                <h4 className="fw-bolder text-primary">{instructor?.displayName}</h4>

                <p>
                  Subjects:
                  <ul>
                    {userModules?.map((module, index) => (
                      <li className="my-1" key={index}>
                        {module}
                      </li>
                    ))}
                  </ul>
                </p>
                <p className="fw-bolder text-secondary">Total Learners : {data?.numStudents}</p>
                <p className="fw-bolder text-secondary">Average Ratings : {data?.avgRating}</p>
              </div>
            </div>
          </div>

          <h3 className="my-3 mt-5">Subjects Created</h3>
          <div style={styleMb} className="subjects">
            {publishedAndReviewedSubjects?.map((subject) => (
              <Link
                key={subject.name}
                state={{ data: subject }}
                style={{ textDecoration: "none" }}
                to={`/display-subjects/${subject.name}`}
                className="my-5 col subject bg-dark h-100 shadow-lg p-3  bg-white rounded"
              >
                <img
                  className="subject-img"
                  src={
                    subject.file
                      ? subject.file.url
                      : "https://source.unsplash.com/random/30x30?sig=3"
                  }
                  alt="..."
                />
                <div className="subjects-cont">
                  <p className="fw-bolder">{subject.name}</p>

                  <div>
                    <p>
                      Average Rating:{" "}
                      {calculateAverageRating(subject.reviews).toFixed(2)}
                    </p>
                    <StarIcons
                      averageRating={calculateAverageRating(subject.reviews)}
                    />
                  </div>
                  <p>learners : {subject.students?.length}</p>

                  <p className="text-center my-2">---</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <p>Instructor not found.</p>
      )}
    </div>
  );
};

export default Instructor;
