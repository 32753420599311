import React, { useContext, useEffect, useState } from "react";
import { UsersContext } from "./Contexts/UsersContext";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";

const UserLeaderboard = ({ username }) => {
  const { users } = useContext(UsersContext);
  const { subjects: subjectsFromCommunity } = useContext(CommunityContext);
  const { subjects: subjectsFromSubjects } = useContext(SubjectsContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const allSubjects = [
      ...(Array.isArray(subjectsFromCommunity) ? subjectsFromCommunity : []),
      ...(Array.isArray(subjectsFromSubjects) ? subjectsFromSubjects : [])
    ];

    const fetchUserData = () => {
      const user = users.find((user) => user.displayName === username);

      if (!user) return;

      const { displayName, photoURL,uid, is_instructor, school } = user;

      let totalCorrectAnswers = 0;
      let quizzesCount = 0;
      let challengesCount = 0;
      let responsesCount = 0;
      let reviewsCount = 0;
      let userPoints = 0;

      user.answeredQuestionsLog?.forEach((log) => {
        log.quizResults.forEach((result) => {
          if (result.isCorrect) {
            totalCorrectAnswers++;
          }
        });
      });

      allSubjects.forEach((subject) => {
        quizzesCount += subject.quizzes.filter((quiz) => quiz.displayName === displayName).length;
        challengesCount += subject.challenges.filter((challenge) => challenge.name === displayName).length;

        if (subject.challenges) {
          subject.challenges.forEach((challenge) => {
            if (challenge.responses) {
              responsesCount += challenge.responses.filter((response) => response.displayName === displayName).length;

              challenge.responses.forEach((response) => {
                if (response.reviews) {
                  reviewsCount += response.reviews.filter((review) => review.displayName === displayName).length;
                }
              });
            }
          });
        }

        // Add points for teachers if their name matches the subject displayName
        if (subject?.uid === uid) {
          userPoints += 5;
        }
      });

      userPoints += totalCorrectAnswers; // 1 point for each correct answer
      userPoints += 2 * quizzesCount; // 5 points for each quiz
      userPoints += 2 * challengesCount; // 5 points for each challenge
      userPoints += 2 * responsesCount; // 5 points for each response
      userPoints += 2 * reviewsCount; // 3 points for each review

      // Determine rank based on sorted points
      const sortedUsers = users
        .map((user) => {
          let points = 0;
          let totalCorrect = 0;

          user.answeredQuestionsLog?.forEach((log) => {
            log.quizResults.forEach((result) => {
              if (result.isCorrect) {
                totalCorrect++;
              }
            });
          });

          points += totalCorrect;
          points += 5 * allSubjects.reduce((acc, subject) => acc + subject.quizzes.filter((quiz) => quiz.displayName === user.displayName).length, 0);
          points += 5 * allSubjects.reduce((acc, subject) => acc + subject.challenges.filter((challenge) => challenge.name === user.displayName).length, 0);
          points += 5 * allSubjects.reduce(
            (acc, subject) => acc + subject.challenges.reduce(
              (acc2, challenge) => acc2 + challenge.responses.filter((response) => response.displayName === user.displayName).length, 0
            ), 0
          );
          points += 3 * allSubjects.reduce(
            (acc, subject) => acc + subject.challenges.reduce(
              (acc2, challenge) => acc2 + challenge.responses.reduce(
                (acc3, response) => acc3 + response.reviews.filter((review) => review.displayName === user.displayName).length, 0
              ), 0
            ), 0
          );

          return { ...user, points };
        })
        .sort((a, b) => b.points - a.points);

      const rank = sortedUsers.findIndex((u) => u.displayName === username) + 1;

      // Determine league
      let league = "Lower League";
      if (rank <= 4) {
        league = "Top League";
      } else if (rank <= 9) {
        league = "Middle League";
      }

      setUserData({
        displayName,
        photoURL,
        totalCorrectAnswers,
        quizzesCount,
        challengesCount,
        responsesCount,
        reviewsCount,
        points: userPoints,
        school,
        rank,
        league,
      });
    };

    fetchUserData();
  }, [subjectsFromCommunity, subjectsFromSubjects, users, username]);

  const renderAchievementIcon = (points) => {
    if (points >= 1000000) {
      return <i className="fas fa-trophy gold-star"></i>; // Trophy icon for 1M or more points
    } else if (points >= 1000) {
      return <i className="fas fa-star gold-star"></i>; // Star icon for 1000 or more points
    } else if (points >= 100) {
      return <i className="fas fa-seedling gold-star"></i>; // Flower icon for 100 or more points
    } else {
      return null; // No icon for less than 100 points
    }
  };

  const renderLeagueBadge = (league) => {
    switch (league) {
      case "Top League":
        return <i className="fas fa-medal gold-badge"></i>; // Gold badge for Top League
      case "Middle League":
        return <i className="fas fa-medal silver-badge"></i>; // Silver badge for Middle League
      case "Lower League":
        return <i className="fas fa-medal bronze-badge"></i>; // Bronze badge for Lower League
      default:
        return null;
    }
  };

  return (
    <div className="container my-5">
      <h4 className="text-primary my-4">League Rank</h4>
      {userData ? (
        <table className="table">
          <thead>
            <tr>
              <th>Overall Position</th>
              <th>Total Points</th>
              <th>League</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-bolder ">{userData.rank}</td>
              <td className="fw-bolder text-primary">
                {userData.points >= 1000000
                  ? `${(userData.points / 1000000).toFixed(2)}M`
                  : userData.points >= 1000
                  ? `${(userData.points / 1000).toFixed(2)}K`
                  : userData.points}
              </td>
              <td className="fw-bolder">
                {userData.league} {renderLeagueBadge(userData.league)}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p>User not found or no data available.</p>
      )}
    </div>
  );
};

export default UserLeaderboard;
