import React, { useState, useEffect } from 'react';

function InstallButton() {
  const [isInstallPromptAvailable, setIsInstallPromptAvailable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const promptEventListener = (event) => {
      event.preventDefault();
      console.log('beforeinstallprompt event fired');
      setDeferredPrompt(event); // Store the event for later
      setIsInstallPromptAvailable(true); // Show the install button
    };
  
    window.addEventListener('beforeinstallprompt', promptEventListener);
  
    return () => {
      window.removeEventListener('beforeinstallprompt', promptEventListener);
    };
  }, []);
  

  // Handle the install button click
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the native install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null); // Reset the deferred prompt
        setIsInstallPromptAvailable(false); // Hide the install button
      });
    }
  };

  return (
    isInstallPromptAvailable && (
      <button onClick={handleInstallClick} className="install-btn m-2">
        Install App
      </button>
    )
  );
}

export default InstallButton;
