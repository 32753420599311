import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import { projectStorage as storage } from "../firebase";

export default function Signup() {
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const schoolRef = useRef();
  const codeRef = useRef();
  const photoRef = useRef(); // Added ref for the photo file input
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [photoFile, setPhotoFile] = useState(null); // Added state for the photo file

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];

      if (imageExtensions.includes(extension)) {
        setPhotoFile(file);
      } else {
        setError("Please select a valid image file (jpg, jpeg, png, gif)");
      }
    } else {
      setError("Please select an image");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    /**
    if (!/^\d{3}[A-Za-z]{3}$/.test(codeRef.current.value)) {
      return setError("Code must be 3 digits followed by 3 letters.");
    }
     */
    try {
      setError("");
      setLoading(true);

      const usernameExists = await checkUsernameExists(usernameRef.current.value);
      if (usernameExists) {
        setLoading(false);
        return setError("Username already exists. Please choose another one.");
      }

      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(
          emailRef.current.value,
          passwordRef.current.value
        );

      await user.updateProfile({
        displayName: usernameRef.current.value
      });

      // Upload the photo and get the URL
      let photoUrl = "";
      if (photoFile) {
        const storageRef = storage.ref();
        const fileRef = storageRef.child(photoFile.name);
        await fileRef.put(photoFile);
        photoUrl = await fileRef.getDownloadURL();
      }

      const newUser = {
        uid: user.uid,
        displayName: usernameRef.current.value,
        email: emailRef.current.value,
        school: schoolRef.current.value,
        code: "",
        disabled: false,
        bio: "",
        lastReadMessageId: "",
        photoURL: photoUrl,
        answeredQuestionsLog: [],
      };

      // Check if the users document exists
      const userDocRef = firebase.firestore().collection("usersCollection").doc("usersDoc");
      const doc = await userDocRef.get();
      if (!doc.exists) {
        // Create the document if it does not exist
        await userDocRef.set({ users: [] });
      }

      // Add the new user to the users array
      await userDocRef.update({
        users: firebase.firestore.FieldValue.arrayUnion(newUser)
      });

      alert("Signed up successfully");
      navigate("/");
      window.location.reload(); // Reload the page after navigating to home
    } catch (err) {
      console.error("Failed to create an account", err);
      if (err.code === 'auth/network-request-failed') {
        setError("Network error. Please check your internet connection and try again.");
      } else {
        setError("Failed to create an account. " + err.message);
      }
    }

    setLoading(false);
  }

  async function checkUsernameExists(username) {
    try {
      const snapshot = await firebase.firestore().collection("usersCollection").doc("usersDoc").get();
      const users = snapshot.data()?.users || [];
      return users.some(user => user.displayName === username);
    } catch (err) {
      console.error("Error checking username", err);
      setError("Error checking username. " + err.message);
      return false;
    }
  }

  return (
    <div className="container xs-container-fluid mt-5 mb-5">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <div className="spinner"></div>
            <p>Creating account, Please Wait...</p>
          </div>
        </div>
      )}

      <section className="mb-5 mt-5">
        <div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 col-xl-11">
              <div className="text-black">
                <div className="card-body p-md-5">
                  <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">
                      <p className="text-center fw-bolder">Sign up</p>
                      <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              ref={usernameRef}
                              required
                              type="text"
                              id="form3Example1c"
                              className="form-control"
                            />
                            <label className="form-label" htmlFor="form3Example1c">
                              Your Name
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="email"
                              id="form3Example3c"
                              className="form-control"
                              ref={emailRef}
                              required
                            />
                            <label className="form-label" htmlFor="form3Example3c">
                              Your Email
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="form3Example4c"
                              className="form-control"
                              ref={passwordRef}
                              required
                            />
                            <label className="form-label" htmlFor="form3Example4c">
                              Password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="password"
                              id="form3Example4cd"
                              className="form-control"
                              ref={passwordConfirmRef}
                              required
                            />
                            <label className="form-label" htmlFor="form3Example4cd">
                              Repeat your password
                            </label>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-school fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <select
                              ref={schoolRef}
                              required
                              id="form3Example5c"
                              className="form-control"
                            >
                              <option value="">Select your school</option>
                              <option value="Nngweni Secondary">Nngweni Secondary</option>
                              <option value="Patrick Ramaano Secondary">Patrick Ramaano Secondary</option>
                              <option value="Other">Other</option>
                            </select>
                            <label className="form-label" htmlFor="form3Example5c">
                              School
                            </label>
                          </div>
                        </div>
                        {/*
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-code fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="text"
                              id="form3Example6c"
                              className="form-control"
                              ref={codeRef}
                              required
                            />
                            <label className="form-label" htmlFor="form3Example6c">
                              Code (3 digits, 3 letters)
                            </label>
                          </div>
                        </div>
                        */}
                        <div className="d-flex flex-row align-items-center mb-4">
                          <i className="fas fa-camera fa-lg me-3 fa-fw"></i>
                          <div className="form-outline flex-fill mb-0">
                            <input
                              type="file"
                              id="form3Example7c"
                              className="form-control"
                              ref={photoRef}
                              onChange={handlePhotoUpload}
                            />
                            <label className="form-label" htmlFor="form3Example7c">
                              Upload Photo
                            </label>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                          <Button type="submit" className="btn btn-primary w-75" disabled={loading}>
                            {loading ? "Loading..." : "Register"}
                          </Button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                      <img
                        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                        className="img-fluid"
                        alt="Sample image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
