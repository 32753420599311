import React, { useContext, useState } from "react";
import { projectFirestore, auth } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactPlayer from "react-player";
import PostResponse from "./PostResponse";
import GroupsContext from "./Contexts/GroupsContext";

function DisplayGroup({ community_id, community_name }) {

  const values = useContext(GroupsContext);

  const subjects = values.subjects && values.subjects
  const [sub, setSub] = useState("")
  const [replies, setReplies] = useState(false);

  const filteredSubjects = values.subjects?.filter(
    (subject) =>
      subject.name.toLowerCase().includes(sub.toLowerCase()) ||
      subject.description.toLowerCase().includes(sub.toLowerCase()) ||
      subject.displayName.toLowerCase().includes(sub.toLowerCase())
  );

  const getFileType = (fileUrl) => {
    const extension = fileUrl?.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const audioExtensions = ["mp3", "wav", "ogg"];
    const videoExtensions = ["mp4", "avi", "mkv", "mov"];
    const documentExtensions = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ];

    if (imageExtensions.includes(extension)) {
      return "Image";
    } else if (audioExtensions.includes(extension)) {
      return "Audio";
    } else if (videoExtensions.includes(extension)) {
      return "Video";
    } else if (documentExtensions.includes(extension)) {
      return "Document";
    } else {
      return "File";
    }
  };

  return (
    <div className="mt-5 group">
      <div>
        <h6>Posts</h6>
      </div>
      <form className="sm-mx-1 my-1 mx-1 d-flex">
        <div className="row sm-mx-1">
          <div className="col-12">
            <input
              className="sm-mx-1 form-control me-sm-2 input"
              type="search"
              placeholder="Search"
              onChange={(e) => setSub(e.target.value)}
            />
          </div>
        </div>
      </form>

      <div className="post-wrapper mt-5 mb-5">
        <div className="row g-0">
          <div className="col-lg-9 col-md-12">
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <section className="w-100">
                  <div className="w-100">
                    <div className="w-100 px-0 mx-0 h-100 ">
                      {filteredSubjects &&
                        filteredSubjects.map((sub) => (
                          <div className="col mb-5 w-100 px-0 mx-0 wrapper">
                            <div className="content-container">
                              <div className="w-100">
                                <div className="content-files w-100">
                                  {getFileType(sub.file?.name) === "Audio" ? (
                                    <div className="audio my-3 mx-0 p-0">
                                      <audio
                                        src={sub.file?.url}
                                        controls
                                      />
                                    </div>
                                  ) : getFileType(sub.file?.name) ===
                                    "Video" ? (
                                    <div className="video player-wrapper">
                                      <ReactPlayer
                                        url={sub.file?.url}
                                        width="100%"
                                        height="100%"
                                        wrapper="div"
                                        className="react-player px-0 mx-0"
                                        controls
                                      />
                                    </div>
                                  ) : getFileType(sub.file?.name) ===
                                    "Image" ? (
                                    <div className="image">
                                      <img src={sub.file?.url} alt="..." />
                                    </div>
                                  ) : getFileType(sub.file?.name) ===
                                    "Document" ? (
                                    <div className="document">
                                      <a
                                        href={sub.file?.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {sub.file?.name}
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <p></p>
                                    </div>
                                  )}
                                </div>
                                <div className="card-body mt-5 p-2">
                                  <div className="text-start">
                                    <div className="comment-profile-name">
                                      <div className="comment-profile">
                                        <img
                                          src={
                                            sub.photoURL ||
                                            "https://source.unsplash.com/random/30x30?sig=3"
                                          }
                                        />
                                      </div>
                                      <p className="px-2">
                                        {sub.displayName && sub.displayName}
                                      </p>
                                    </div>

                                    <p className="my-2 text-danger">
                                      {sub.name && sub.name}
                                    </p>
                                  </div>
                                  <div className="text-start">
                                    <p>{sub.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!replies ? (<>
                              <p className="my-1 text-primary" onClick={() => setReplies(true)}>replies</p>
                            </>) : <>
                              <div>
                                <p className="my-1 text-danger" onClick={() => setReplies(false)}>close replies</p>
                                <PostResponse subjectId={sub?.c_id} />
                              </div>
                            </>}

                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayGroup;
