import React, { useEffect, useState, useContext } from "react";
import SubjectsContext from "../Pages/Contexts/SubjectContext";
import { Link } from "react-router-dom";
import { auth, projectFirestore as firestore } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Book, Trophy, User, LogIn, UserPlus, Home } from "lucide-react"; // Added Home icon

function Navigation() {
  const [user] = useAuthState(auth);
  const values = useContext(SubjectsContext);
  const [navBackground, setNavBackground] = useState(false);
  const [userDoc, setUserDoc] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const subjects = values?.subjects;

  useEffect(() => {
    if (user) {
      const unsubscribeUsers = firestore
        .collection("usersCollection")
        .doc("usersDoc")
        .onSnapshot((doc) => {
          const usersData = doc.data()?.users || [];
          setUsers(usersData);
          setUserDoc(usersData?.find((u) => u.displayName === user.displayName));
          setIsLoading(false);
        });

      return () => unsubscribeUsers();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const handleScroll = () => setNavBackground(window.scrollY > 100);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navStyle = {
    backgroundColor: navBackground ? "black" : "transparent",
    transition: "background-color 0.5s ease-out",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    width: "100%",
  };

  return (
    <div>
      <nav className="navbar navbar-dark bg-dark py-2" style={navStyle}>
        <div className="container d-flex justify-content-between align-items-center">
          <Link to="/" className="navbar-brand  text-light fw-bold">
          <Home size={30} /> <span className=" text-success">Scul</span> 
          </Link>
          <ul className="navbar-nav d-flex align-items-center flex-row">
           
            {isLoading ? (
              <li className="nav-item mx-2">
                <span className="nav-link text-light">Loading...</span>
              </li>
            ) : (
             
              <li className="nav-item mx-2">
              <Link to="/display-subjects" className="nav-link text-light"> <Book size={26} /></Link>
            </li>
            )}
            
            <li className="nav-item mx-2">
              <Link to="/all-quiz-scores" className="nav-link text-light"><Trophy size={26} /></Link>
            </li>


            {user && user.displayName?.includes("Mphashi") && (
              <li className="nav-item mx-2">
                <Link to="/review-subjects" className="nav-link text-light">Review</Link>
              </li>
            )}

            {user ? (
              <li className="nav-item mx-2">
                <Link to="/profile" className="nav-link text-light">
                  <User size={26} />

                </Link>
              </li>
            ) : (
              <>
                
                <li className="nav-item mx-2">
                  <Link to="/login" className="nav-link text-light">
                    <UserPlus size={26} />
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
      <header
        className="py-2"
        style={{ backgroundColor: "#393f4d", marginTop: "34px" }}
      >
        <div className="container px-2 px-lg-2 my-2">
          <div className="text-start text-light">
            <h4 className="display-5 text-success"></h4>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Navigation;
