import React, { useContext, useState, useEffect } from "react";
import { projectFirestore, auth } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";
import Challenges from "./Challenges";
import CreateChallenges from "./CreateChallenges";
import Quizzes from "./Quizzes";
import QuizScores from "./QuizScores";
import Message from "./Message";
import { FaTrashAlt } from "react-icons/fa";

function DisplayLesson({ subject, route }) {
  const [user] = useAuthState(auth);
  const values = useContext(SubjectsContext);
  const [openClasses, setOpenClasses] = useState(false);
  const [openQuestions, setOpenQuestions] = useState(true);
  const [openQuizzes, setOpenQuizzes] = useState(false);
  const [openChats, setOpenChats] = useState(false);
  const [studentRankings, setStudentRankings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const subjectsRef = projectFirestore.collection(route);
  const query = subjectsRef.orderBy("createdAt");

  const [lessons, loading] = useCollectionData(query, { idField: "id" });

  const toggleSection = (section) => {
    setOpenClasses(section === "classes");
    setOpenQuestions(section === "questions");
    setOpenQuizzes(section === "quizzes");
    setOpenChats(section === "chats");
    setTimeout(() => {
      const sectionId = document.getElementById(section);
      if (sectionId) {
        sectionId.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  let isDisabled = true;
  const getSubject = values.subjects?.filter((sub) => sub.name === subject);

  const totalLessons = getSubject?.[0]?.lessons?.length || 0;
  const lessonsCompleted =
    getSubject?.[0]?.lessons?.filter((lesson) =>
      lesson.students?.includes(user.displayName)
    ).length || 0;

  useEffect(() => {
    if (getSubject && getSubject[0]) {
      const studentRankingsObj = {};
      getSubject[0].lessons?.forEach((lesson) => {
        lesson.students?.forEach((student) => {
          if (studentRankingsObj[student]) {
            studentRankingsObj[student]++;
          } else {
            studentRankingsObj[student] = 1;
          }
        });
      });

      const rankingsArray = Object.keys(studentRankingsObj).map((student) => ({
        student,
        lessonsIncluded: studentRankingsObj[student],
      }));

      rankingsArray.sort((a, b) => b.lessonsIncluded - a.lessonsIncluded);

      setStudentRankings(rankingsArray);
    }
  }, [getSubject]);

  if (loading) {
    return <div>Loading activities...</div>;
  }

  if (!getSubject || !getSubject[0]) {
    return <div>No subject found or the data is still loading.</div>;
  }

  const getEnrolled = getSubject?.map((sub) =>
    sub.students?.includes(user.displayName)
  );

  if (getEnrolled && getEnrolled[0] === true) {
    isDisabled = false;
  }

  if (getSubject[0]?.displayName === user.displayName) {
    isDisabled = false;
  }

  return (
    <>
      <div>
        <section className="py-2 mt-1 mb-1">
           
          <div className="d-flex flex-wrap gap-3">
           {/** 
            <button
              onClick={() => toggleSection("classes")}
              className="btn btn-success py-3 px-4 text-light mx-1 my-3 mb-5"
            >
              Classes
            </button>
            */}
            
           
            <button
              onClick={() => toggleSection("questions")}
              className="btn btn-primary py-2 px-3 text-light mx-1 my-3 mb-5"
              disabled={isDisabled}
            >
              Questions
            </button>
            <button
              onClick={() => toggleSection("chats")}
              className="btn btn-secondary py-2 px-3 text-light my-3 mb-5"
              disabled={isDisabled}
            >
              Send Question
            </button>
            <button
              onClick={() => toggleSection("quizzes")}
              className="btn btn-dark py-2 px-3 text-light mx-1 my-3 mb-5"
              disabled={isDisabled}
            >
              Quizzes
            </button>
           
          </div>
       
          {openClasses && (
            <div id="classes">
              <h5 className="mb-3 text-success">Classes</h5>
              <div className="progress my-5">
                <div
                  className="progress-bar text-success"
                  role="progressbar"
                  style={{
                    width: `${(lessonsCompleted / totalLessons) * 100}%`,
                  }}
                  aria-valuenow={lessonsCompleted}
                  aria-valuemin={0}
                  aria-valuemax={totalLessons}
                >
                  {`${lessonsCompleted} of ${totalLessons} Lessons Completed`}
                </div>
              </div>

              <div className="mx-2 my-3">
                {getSubject?.[0]?.lessons?.map((lesson, index) => (
                  <div className="my-5 lesson-delete quiz-card shadow-lg p-3 mb-5 bg-white rounded" key={index}>
                    <p>
                      Class {index + 1}
                    </p>
                    <br />
                    <Link
                      to={`${lesson.l_id}`}
                      state={{ data: lesson }}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <div className="card-bod p-1">
                          <h4 className="fw-bolder">
                            <div dangerouslySetInnerHTML={{ __html: lesson.topic }}></div>
                          </h4>
                          <hr />
                          <p>
                            <div dangerouslySetInnerHTML={{ __html: lesson.description.slice(0, 300) }}></div>
                          </p>
                        </div>
                        <button className="mt-3 p-2 bg-dark">
                          <Link to={`${lesson.l_id}`} state={{ data: lesson }} style={{ textDecoration: "none" }}>
                            Open Class Content
                          </Link>
                        </button>
                      </div>
                    </Link>
                    {user.displayName === getSubject[0]?.displayName && (
                      <FaTrashAlt
                        className="delete-icon"
                        onClick={(e) =>
                          values.deleteLesson(e, getSubject[0].sub_id, lesson.lesson_id)
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {openQuestions && (
            <div id="questions" className="container my-3">
             
              <Challenges challenges={getSubject[0]?.challenges} type="subject" />
            </div>
          )}

          {openQuizzes && (
            <div id="quizzes" className="container my-3">
              <p className="mx-1 my-5 fw-bolder text-info">Quizzes</p>
              <Quizzes data={getSubject[0]?.quizzes} type="subject" />
              <hr />
              <div className="container my-5">
                <QuizScores subject={getSubject[0]?.name} students={getSubject[0]?.students} />
              </div>
            </div>
          )}

          {openChats && (
            <div id="questions" className="container my-3">
            <div className="my-2 quiz-card shadow-lg p-3 mb-5 bg-white rounded">
              <CreateChallenges subjectId={getSubject[0]?.name} type="subject" />
            </div>
            
          </div>
          )}

        </section>
      </div>
    </>
  );
}

export default DisplayLesson;
